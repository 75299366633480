import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './onboarding.scss';
import * as actions from '../../redux/actions';
import { Row, Col, Button } from 'react-bootstrap';
import onboardinglogo from '../../assets/logos/onboardinglogo.svg';
import videoimg from '../../assets/images/videoimg.png';
import config from '../../config';

class Onboarding extends Component {
  state = {
    videOpen: false,
  };

  componentDidMount() {
    if (window.innerWidth >= 1024) {
      this.props.history.push(config.publicRoute + `/login`);
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.props.actions.getAppGlobals(true);
  }

  goLogin = () => {
    this.props.history.push(config.publicRoute + `/login`);
  };

  openVideo = () => {
    this.setState({
      videOpen: true,
    });
  };

  render() {
    const { videOpen } = this.state;
    return (
      <div className='onboarding'>
        <div className='onboarding-overlay' />
        <div className='full-center onboarding-header'>
          <img
            className='onboarding-logo'
            src={onboardinglogo}
            alt='covifactura logo'
          />
        </div>
        <Col className='onboarding-body'>
          <Row>
            <Col xs={12} className='slogan-text'>
              ¿Qué es <b>Covifactura</b>
              <br /> <b>para tesos?</b>
            </Col>

            {!videOpen ? (
              <div className='video-container full-center'>
                <img
                  className='onboarding-video-img'
                  src={videoimg}
                  alt='covifactura logo'
                />
                <div
                  className='play-button full-center'
                  onClick={(e) => {
                    this.openVideo();
                  }}
                >
                  <div className='play-icon' />
                </div>
                <div
                  className='open-video-link'
                  onClick={(e) => {
                    this.openVideo();
                  }}
                >
                  Ver video
                </div>
              </div>
            ) : (
              <div className='video-container full-center'>
                <video width='100%' controls autoPlay>
                  <source
                    poster={videoimg}
                    src='/onboarding.mp4'
                    type='video/mp4'
                  />
                </video>
              </div>
            )}

            <Col xs={{ span: 8, offset: 2 }} className='button-container'>
              <Button
                className='button-primary'
                variant='primary'
                onClick={(e) => {
                  e.preventDefault();
                  this.goLogin();
                }}
                block
              >
                INGRESAR
              </Button>
            </Col>
            <Col xs={12} className='onboarding-footer'>
              © 2020 Un servicio de
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
