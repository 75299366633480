import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import './home.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import ClientHeader from '../client-header';
import emptystate from '../../assets/icons/emptystate.svg';
import Loading from '../loading';
import Menu from '../menu';
import TransactionItem from '../transaction-item';
import config from '../../config';

// const customers = [
//   { id: 1, name: 'Madecentro'},
//   { id: 2, name: 'Alfagres'},
//   { id: 3, name: 'Casa Toro'},
// ];

class Home extends Component {
  constructor() {
    super();
    this.state = {
      uniqueCustomer: false,
      multipleCustomer: true,
      selectedCustomer: '',
      menuOpen: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userQuota !== this.props.userQuota) {
      if (nextProps.userQuota.CUPOS.length > 0) {
        this.setState(
          {
            selectedCustomer: nextProps.userQuota.CUPOS[0].AFILIADO_DOCUMENTO,
          },
          () => {
            // let selectedQuota = nextProps.userQuota.CUPOS[0]
            // this.props.actions.getTransactions({
            //   customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
            //   customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
            //   transactionStatus: 'AL DIA,NEGOCIADA',
            //   branch: null
            // });
            // this.props.actions.setTransaction({
            //   currentTransaction: null,
            //   currentCustomer: nextProps.userQuota.CUPOS[0].AFILIADO_DOCUMENTO
            // });
          }
        );
      }
    }
  }

  loadData() {
    this.getUserQuota();
    this.getTransactions();
  }

  getTransactions = () => {
    this.props.actions.getTransactions({
      customerDocumentType: null,
      customerDocument: null,
      transactionStatus: 'AL DIA,VENCIDO,RECLAMADA',
      branch: null,
    });
  };

  getUserQuota = () => {
    this.props.actions.getUserQuota();
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    return thousand;
  }

  searchAction = () => {
    this.props.history.push(config.publicRoute + '/buscar');
  };

  goEstablishments = () => {
    this.props.history.push(config.publicRoute + '/establecimientos');
  };

  goDetail = (item) => {
    this.props.actions.setTransaction({
      currentTransaction: item,
    });
    window.gtag('event', 'detalle', {
      event_category: item.ESTABLECIMIENTO,
      event_label: item.ESTABLECIMIENTO,
    });
    this.props.history.push(config.publicRoute + '/detalle');
  };

  getDiferenceDays = (expirationDate) => {
    let momentDate = moment(expirationDate);
    let momentTodayDate = moment();
    let days = momentDate.diff(momentTodayDate, 'days');
    return [momentDate.format('DD/MMM/YYYY'), days];
  };

  getItem = (item, index, record) => {
    let dateArray = this.getDiferenceDays(item.FECHA_VENCIMIENTO);
    let dateBuyArray = this.getDiferenceDays(item.FECHA_COMPRA);
    let days = parseInt(dateArray[1]);
    let upToDate = item.ESTADO === 'AL DIA' || item.ESTADO === 'NEGOCIADA';
    let dateText = dateArray[0];
    let dateBuyText = dateBuyArray[0];
    dateText = dateText.split('.').join('');
    dateBuyText = dateBuyText.split('.').join('');
    return (
      <TransactionItem
        item={item}
        dateText={dateText}
        dateBuyText={dateBuyText}
        days={days}
        quota={this.getStablishment(item.ESTABLECIMIENTO)}
        goDetail={this.goDetail}
        key={index}
      />
    );
  };

  openMenu = () => {
    this.setState({
      menuOpen: true,
    });
  };

  closeMenu = () => {
    this.setState({
      menuOpen: false,
    });
  };

  getStablishment = (name) => {
    const { userQuota } = this.props;
    let quotas = userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
    if (quotas.length === 0) return null;
    let quota = quotas.find((item) => {
      return item.AFILIADO_NOMBRES === name;
    });
    return quota;
  };

  render() {
    const {
      // uniqueCustomer,
      // multipleCustomer,
      // selectedCustomer,
      menuOpen,
    } = this.state;
    const { userQuota, loading, transactionLoading, transactionsResponse } =
      this.props;
    let quotas = userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
    // let defaultQuota = quotas.length > 0 ? quotas[0] : ''
    // let selectedQuota = selectedCustomer !== '' ? quotas.find(quota => quota.AFILIADO_DOCUMENTO === selectedCustomer) : defaultQuota
    // let quota = selectedQuota !== '' ? selectedQuota.CUPO_ASIGNADO : 0
    // let quotaAvailable = selectedQuota !== '' ? selectedQuota.CUPO_DISPONIBLE : 0
    let transactions =
      transactionsResponse && 'TRANSACCIONES' in transactionsResponse
        ? transactionsResponse.TRANSACCIONES
        : [];
    return (
      <div className='home'>
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} />
        <Header menu openMenu={this.openMenu} tab={1} />
        <div className='container'>
          {(loading || transactionLoading) && <Loading />}
          <ClientHeader />
          <Col xs={12} className='purchases-title-container'>
            <Row>
              <Col xs={12} className='purchases-title'>
                Compras respaldadas
              </Col>
              <Col xs={4}>
                <Col className='purchases-title-border' />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className='purchases-container'>
            {transactions.length > 0 && (
              <Row className='purchases-items-container'>
                {transactions.map((item, index) => {
                  return this.getItem(item, index);
                })}
              </Row>
            )}
            {transactions.length === 0 && (
              <Row className='purchases-items-container'>
                <Col xs={12} className='empty-img-container'>
                  <img
                    className=''
                    src={emptystate}
                    alt='icono de pantalla vacía'
                  />
                </Col>
                <Col xs={12} className='empty-title hide-mobile'>
                  ¡Usted aún no tiene compras respaldadas!
                </Col>
                <Col xs={12} className='empty-title hide-desktop'>
                  ¡Usted aún no tiene
                  <br /> compras respaldadas!
                </Col>
                <Col xs={12} className='empty-description hide-mobile'>
                  Recuerde que cuenta con un cupo disponible el cual
                  <br /> puede consultar haciendo clic en establecimientos.
                </Col>
                <Col xs={12} className='empty-description hide-desktop'>
                  Recuerde que cuenta con un cupo
                  <br /> disponible el cual puede consultar
                  <br /> haciendo clic en establecimientos.
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 4 }}>
                  <button
                    className='stablishment-button'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goEstablishments();
                    }}
                  >
                    VER ESTABLECIMIENTOS
                  </button>
                </Col>
              </Row>
            )}
          </Col>
        </div>
        <Chat />
        <Footer tab={1} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { globals, loading, userQuota, user } = state.user;
  let {
    loading: transactionLoading,
    transactionsResponse,
    currentTransaction,
    currentCustomer,
  } = state.transaction;
  return {
    globals,
    loading,
    userQuota,
    user,
    transactionLoading,
    transactionsResponse,
    currentTransaction,
    currentCustomer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
