import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './enter-code.scss';
import * as actions from '../../redux/actions';
import { Row, Col, Button } from 'react-bootstrap';

import leftArrow from '../../assets/icons/left-arrow.svg';
import loginicon from '../../assets/icons/loginicon.svg';
import phone from '../../assets/icons/phone.svg';
import email from '../../assets/icons/email.svg';
import { debounce } from 'debounce';
import Loading from '../loading';
import LoginBackground from '../login-background';
import newlogo from '../../assets/logos/newlogo.svg';
import FloatingFooter from '../floating-footer';
import Chat from '../chat';
import config from '../../config';

class EnterCode extends Component {
  constructor() {
    super();
    this.state = {
      codeNumber: '',
      validCodeNumber: true,
      showInvalidCodeError: false,
    };
    this.isValidCodeNumber = debounce(this.isValidCodeNumber, 1000);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {}

  goBack = () => {
    const { selectedContact } = this.props;
    this.props.actions.setSelectedContact(selectedContact);
    this.props.history.push(config.publicRoute + `/seleccionar-contacto`);
  };

  isValidCodeNumber(codeNumber) {
    if (!codeNumber) {
      this.setState({
        validCodeNumber: true,
      });
      return;
    }
    let validCodeNumber = false;
    if (/^[A-Z]{1}[0-9]{6}$/i.test(codeNumber) && codeNumber.length >= 7) {
      validCodeNumber = true;
    }
    this.setState({
      validCodeNumber,
    });
  }

  isValidCodeNumberButton() {
    const { codeNumber } = this.state;
    let valid = false;
    if (/^[A-Z]{1}[0-9]{6}$/i.test(codeNumber) && codeNumber.length >= 7) {
      valid = true;
    }
    return valid;
  }

  isCodeNumber = (event) => {
    let codeNumber = event.target.value.toUpperCase();
    this.isValidCodeNumber(codeNumber);
    let validCodeNumber = false;
    if (/^[A-Z]{1}[0-9]{6}$/i.test(codeNumber) && codeNumber.length >= 7) {
      validCodeNumber = true;
    }
    if (validCodeNumber) {
      this.setState({
        validCodeNumber,
        codeNumber,
      });
    } else {
      this.setState({
        codeNumber,
      });
    }
  };

  goToResendCode = () => {
    const { selectedContact } = this.props;
    this.props.actions.setSelectedContact(selectedContact);
    this.props.history.push(config.publicRoute + `/reenviar-codigo`);
  };

  formatData(data, type) {
    let formattedData = '';
    if (type === 'EMAIL') {
      let emailData = data.split('@');
      let firstCharacters = emailData[0].slice(0, 2);
      let lastCharacters = emailData[0].slice(-2);
      formattedData = `${firstCharacters}****${lastCharacters}@${emailData[1]}`;
    } else {
      let firstCharacters = data.slice(0, 3);
      let lastCharacters = data.slice(-3);
      formattedData = `${firstCharacters}****${lastCharacters}`;
    }
    return formattedData;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.codeValidated &&
      nextProps.codeValidated !== this.props.codeValidated
    ) {
      console.log('nextProps.codeValidated', nextProps.codeValidated);
      const { userContacts } = this.props;
      let userDocument = JSON.parse(localStorage.getItem('userDocument'));
      // console.log('userDocument', userDocument);
      window.gtag('event', 'login', {
        event_category: `${userDocument.documentType}:${userDocument.documentNumber}`,
        event_label: 'login',
      });
      this.props.actions.setUser({
        token: userContacts.TOKEN,
        userInfo: nextProps.codeValidated,
      });
      this.props.history.push(config.publicRoute + `/`);
    }
    if (nextProps.userError && nextProps.userError !== this.props.userError) {
      console.log('invalid code');
      this.showInvalidCodeError();
      let userDocument = JSON.parse(localStorage.getItem('userDocument'));
      window.gtag('event', 'login', {
        event_category: `${userDocument.documentType}:${userDocument.documentNumber}`,
        event_label: 'codigo-invalido',
      });
    }
  }

  showInvalidCodeError = () => {
    this.setState(
      {
        showInvalidCodeError: true,
        codeNumber: '',
      },
      () => {
        setTimeout(() => {
          this.setState({ showInvalidCodeError: false });
        }, 4000);
      }
    );
  };

  handleSubmit = () => {
    const { userContacts } = this.props;
    const { codeNumber } = this.state;
    this.props.actions.validateCode({
      code: codeNumber,
      token: userContacts.TOKEN,
    });
  };

  render() {
    const { codeNumber, validCodeNumber, showInvalidCodeError } = this.state;
    const { selectedContact, loading, loadingUserContacts } = this.props;
    return (
      <div className='enter-code login'>
        <Col>
          <Row className='full-center login-container'>
            <LoginBackground />
            <div className='login-logo-tablet hide-only-mobile hide-desktop'>
              <img className='' src={newlogo} alt='covifactura logo' />
            </div>
            <div className='mobile-side login-card'>
              <Col>
                <Row>
                  {(loading || loadingUserContacts) && <Loading />}
                  <Col
                    xs={12}
                    className='select-contact-card_icon-back-container'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goBack();
                    }}
                  >
                    <img
                      className='select-contact_icon-back'
                      src={leftArrow}
                      alt='error icono'
                    />
                    Volver al inicio de sesión
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className='enter-code-card_title'
                  >
                    Para continuar debemos
                    <br />
                    verificar su identidad
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }}>
                    <Row className='enter-code-card_subtitle-container'>
                      <Col xs='auto' className='enter-code-card_subtitle'>
                        Hemos enviado el código al celular
                        <Row className='enter-code-card_option-container'>
                          <Col xs='auto' className='enter-code-card_option'>
                            <Row className='enter-code-card_option-center'>
                              <Col xs='auto' className='enter-code_option-icon'>
                                {selectedContact.TIPO === 'CELULAR' && (
                                  <img
                                    className='enter-code_icon-phone'
                                    src={phone}
                                    alt='telefono icono'
                                  />
                                )}
                                {selectedContact.TIPO !== 'CELULAR' && (
                                  <img
                                    className='enter-code_icon-phone'
                                    src={email}
                                    alt='email icono'
                                  />
                                )}
                              </Col>
                              <Col xs='auto' className='enter-code_option'>
                                {this.formatData(
                                  selectedContact.DATO,
                                  selectedContact.TIPO
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={{ span: 8, offset: 2 }}
                    className='login-input-container'
                  >
                    <label for='codeNumber'>Ingresar código aquí</label>
                    <input
                      id='codeNumber'
                      name='codeNumber'
                      value={codeNumber}
                      className='login-input'
                      onChange={this.isCodeNumber}
                    />
                  </Col>
                  {codeNumber && !validCodeNumber && (
                    <Col xs={{ span: 6, offset: 3 }} className='error-message'>
                      Ingrese un código con el formato correcto
                    </Col>
                  )}

                  {showInvalidCodeError && (
                    <Col xs={{ span: 8, offset: 2 }} className='error-message'>
                      El código ingresado NO es válido, verifíquelo e intente nuevamente.
                    </Col>
                  )}
                  {/* <Col xs={{ span: 10, offset: 1 }} className='input-container'>
                    <TextField
                      id='codeNumber'
                      label='Ingresar el código'
                      className='home-input'
                      margin='dense'
                      value={codeNumber}
                      onChange={this.isCodeNumber}
                      error={codeNumber && !validCodeNumber ? true : false}
                      helperText={
                        codeNumber && !validCodeNumber
                          ? 'Ingrese un código con el formato correcto'
                          : null
                      }
                      fullWidth
                    />
                  </Col> */}
                  <Col xs={{ span: 6, offset: 3 }} className='button-container'>
                    <button
                      disabled={
                        codeNumber === '' || !this.isValidCodeNumberButton()
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleSubmit();
                      }}
                      className='button button-primary secundary-color'
                    >
                      Continuar
                      <img
                        className='button-login'
                        src={loginicon}
                        alt='login icon'
                      />
                    </button>
                  </Col>
                  <Col
                    xs={12}
                    className='send-again'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goToResendCode();
                    }}
                  >
                    ¿No recibió el código?
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </Col>
        <Chat home />
        <FloatingFooter />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    globals,
    loading,
    loadingUserContacts,
    userContacts,
    selectedContact,
    codeValidated,
    userError,
  } = state.user;
  return {
    globals,
    loading,
    loadingUserContacts,
    userContacts,
    selectedContact,
    codeValidated,
    userError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnterCode);
