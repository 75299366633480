import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './select-contact.scss';
import * as actions from '../../redux/actions';
import { Row, Col, Button } from 'react-bootstrap';
import leftArrow from '../../assets/icons/left-arrow.svg';
import email from '../../assets/icons/email.svg';
import phone from '../../assets/icons/phone.svg';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loading from '../loading';
import LoginBackground from '../login-background';
import newlogo from '../../assets/logos/newlogo.svg';
import FloatingFooter from '../floating-footer';
import Chat from '../chat';
import config from '../../config';

class SelectContact extends Component {
  constructor() {
    super();
    // 52763941
    this.state = {
      selectedContact: { TIPO: null, DATO: null },
    };
  }

  goBack = () => {
    this.props.actions.cleanUserContact();
    this.props.history.push(config.publicRoute + `/login`);
  };

  goToOtherData = () => {
    this.props.actions.cleanUserContact();
    this.props.history.push(config.publicRoute + `/no-son-sus-datos`);
  };

  formatData(data, type) {
    let formattedData = '';
    if (type === 'EMAIL') {
      let emailData = data.split('@');
      let firstCharacters = emailData[0].slice(0, 2);
      let lastCharacters = emailData[0].slice(-2);
      formattedData = `${firstCharacters}****${lastCharacters}@${emailData[1]}`;
    } else {
      let firstCharacters = data.slice(0, 3);
      let lastCharacters = data.slice(-3);
      formattedData = `${firstCharacters}****${lastCharacters}`;
    }
    return formattedData;
  }

  onInputChange = (event, item) => {
    console.log('selectedContact', item);
    this.setState({
      selectedContact: item,
    });
  };

  handleSubmit = () => {
    const { userContacts } = this.props;
    const { selectedContact } = this.state;
    let userDocument = JSON.parse(localStorage.getItem('userDocument'));

    window.gtag('event', 'login', {
      event_category: `${userDocument.documentType}:${userDocument.documentNumber}`,
      event_label: `selecciono_contacto:${selectedContact.DATO}`,
    });
    this.props.actions.setSelectedContact(selectedContact);
    this.props.actions.sendCode({
      contact: selectedContact.DATO,
      // contact: '3113653315',
      token: userContacts.TOKEN,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.codeSended) {
      this.props.history.push(config.publicRoute + `/ingresar-codigo`);
    }
  }

  render() {
    const { userContacts, loading, loadingUserContacts } = this.props;
    const { selectedContact } = this.state;
    let contacts = userContacts ? userContacts.VALIDACIONES : [];
    return (
      <div className='select-contact login'>
        <Col>
          <Row className='full-center login-container'>
            <LoginBackground />
            <div className='login-logo-tablet hide-only-mobile hide-desktop'>
              <img className='' src={newlogo} alt='covifactura logo' />
            </div>
            <div className='mobile-side login-card'>
              <Col>
                <Row>
                  {/* <Col xs={12} className='full-center'>
                  <img
                    className='select-contact-logo'
                    src={loginlogo}
                    alt='covifactura logo'
                  />
                </Col> */}
                  {(loading || loadingUserContacts) && <Loading />}
                  <Col
                    xs={12}
                    className='select-contact-card_icon-back-container'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goBack();
                    }}
                  >
                    <img
                      className='select-contact_icon-back'
                      src={leftArrow}
                      alt='error icono'
                    />
                    Volver al inicio de sesión
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className='select-contact-card_title'
                  >
                    Para continuar debemos
                    <br /> verificar su identidad
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className='select-contact-card_subtitle'
                  >
                    Seleccione en donde desea recibir
                    <br /> su código de seguridad
                  </Col>
                  <Col
                    xs={{ span: 8, offset: 2 }}
                    className='contacts-container'
                  >
                    {contacts.map((item, index) => {
                      return (
                        <Col
                          xs={12}
                          className='select-contact-card_contact'
                          key={index}
                        >
                          <Row>
                            <Col xs='auto' className='full-center'>
                              {item.TIPO === 'CELULAR' && (
                                <img
                                  className='select-contact_icon-phone'
                                  src={phone}
                                  alt='telefono icono'
                                />
                              )}
                              {item.TIPO !== 'CELULAR' && (
                                <img
                                  className='select-contact_icon-email'
                                  src={email}
                                  alt='email icono'
                                />
                              )}
                            </Col>
                            <Col className='select-contact-card_contact-container'>
                              <Row>
                                <Col
                                  xs={12}
                                  className='select-contact-card_contact-key'
                                >
                                  {item.TIPO === 'CELULAR' && 'Celular'}
                                  {item.TIPO !== 'CELULAR' &&
                                    'Correo eletrónico'}
                                </Col>
                                <Col
                                  xs={12}
                                  className='select-contact-card_contact-value'
                                >
                                  {this.formatData(item.DATO, item.TIPO)}
                                </Col>
                              </Row>
                            </Col>
                            <Col xs='auto' className='full-center'>
                              <FormControlLabel
                                checked={item.DATO === selectedContact.DATO}
                                onChange={(event) => {
                                  this.onInputChange(event, item);
                                }}
                                control={<Radio color='primary' />}
                                labelPlacement='start'
                              />
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </Col>
                  <Col xs={{ span: 6, offset: 3 }} className='button-container'>
                    <button
                      disabled={selectedContact.DATO === null}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleSubmit();
                      }}
                      className='button button-primary secundary-color'
                    >
                      Enviar código
                    </button>
                  </Col>
                  <Col
                    xs={12}
                    className='select-contact_link'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goToOtherData();
                    }}
                  >
                    ¿No son sus datos de contacto?
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </Col>
        <Chat home />
        <FloatingFooter />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { globals, loading, loadingUserContacts, userContacts, codeSended } =
    state.user;
  return {
    globals,
    loading,
    loadingUserContacts,
    userContacts,
    codeSended,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectContact);
