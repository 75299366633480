import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './record-search.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import config from '../../config';

class RecordSearch extends Component {
  constructor() {
    super();
    this.state = {
      uniqueCustomer: false,
      multipleCustomer: true,
      selectedCustomer: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {}

  onInputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  closeAction = () => {
    this.props.history.push(config.publicRoute + '/historial');
  };

  render() {
    return (
      <div className='record-search'>
        <Header
          closeAction={this.closeAction}
          search={false}
          searching={true}
          searchPlaceholder='Buscar compras por establecimiento'
          tab={100}
        />
        <div className='container'>
          <Col xs={12} className='purchases-container'>
            <Col xs={12} className='card'>
              <Row className='purchases-title-container'>
                <Col xs={12} className='record-search-item'>
                  <Row>
                    <Col xs={12} className='record-search-item_name'>
                      Madecentro
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Fecha de compra
                        </Col>
                        <Col xs={6} className='record-search-item_value'>
                          00/Ene/2019
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Fecha de pago
                        </Col>
                        <Col xs={6} className='record-search-item_value'>
                          00/Feb/2019
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Número cheque
                        </Col>
                        <Col xs={6} className='record-search-item_value'>
                          2233321-1
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Estado deuda
                        </Col>
                        <Col xs={6} className='record-search-item_status'>
                          Pagado
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Col xs={12} className='record-search-item_border' />
                </Col>
                <Col xs={12} className='record-search-item'>
                  <Row>
                    <Col xs={12} className='record-search-item_name'>
                      Madecentro
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Fecha de compra
                        </Col>
                        <Col xs={6} className='record-search-item_value'>
                          00/Ene/2019
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Fecha de pago
                        </Col>
                        <Col xs={6} className='record-search-item_value'>
                          00/Feb/2019
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Número cheque
                        </Col>
                        <Col xs={6} className='record-search-item_value'>
                          2233321-1
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Estado deuda
                        </Col>
                        <Col xs={6} className='record-search-item_status'>
                          Pagado
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Col xs={12} className='record-search-item_border' />
                </Col>
                <Col xs={12} className='record-search-item'>
                  <Row>
                    <Col xs={12} className='record-search-item_name'>
                      Madecentro
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Fecha de compra
                        </Col>
                        <Col xs={6} className='record-search-item_value'>
                          00/Ene/2019
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Fecha de pago
                        </Col>
                        <Col xs={6} className='record-search-item_value'>
                          00/Feb/2019
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Número cheque
                        </Col>
                        <Col xs={6} className='record-search-item_value'>
                          2233321-1
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='record-search-item_key'>
                          Estado deuda
                        </Col>
                        <Col xs={6} className='record-search-item_status'>
                          Pagado
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Col xs={12} className='record-search-item_border' />
                </Col>
              </Row>
            </Col>
          </Col>
        </div>
        <Chat />
        <Footer tab={100} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordSearch);
