import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import './record.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import clienticon from '../../assets/icons/clienticon.svg';
import recordicon from '../../assets/icons/recordicon.svg';
import Loading from '../loading';
import Menu from '../menu';
import config from '../../config';

class Record extends Component {
  constructor() {
    super();
    this.state = {
      uniqueCustomer: false,
      multipleCustomer: true,
      selectedCustomer: true,
      menuOpen: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userQuota !== this.props.userQuota) {
      if (nextProps.userQuota.CUPOS.length > 0) {
        this.setState(
          {
            selectedCustomer: nextProps.userQuota.CUPOS[0].AFILIADO_DOCUMENTO,
          },
          () => {
            let selectedQuota = nextProps.userQuota.CUPOS[0];
            console.log('selectedQuota', selectedQuota);
            this.props.actions.getTransactionsRecord({
              customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
              customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
              transactionStatus: 'VENCIDO,PAGADA',
              branch: null,
            });
            this.props.actions.setTransaction({
              currentTransaction: null,
              currentCustomer: nextProps.userQuota.CUPOS[0].AFILIADO_DOCUMENTO,
            });
          }
        );
      }
    }
  }

  loadData() {
    if (!this.props.userQuota) {
      this.getUserQuota();
    } else {
      let selectedQuota = this.props.userQuota.CUPOS.find(
        (quota) => quota.AFILIADO_DOCUMENTO === this.props.currentCustomer
      );
      this.props.actions.getTransactionsRecord({
        customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
        customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
        transactionStatus: 'VENCIDO,PAGADA',
        branch: null,
      });
    }
    if (this.props.currentCustomer) {
      this.setState({
        selectedCustomer: this.props.currentCustomer,
      });
    }
  }

  getUserQuota = () => {
    this.props.actions.getUser();
    this.props.actions.getUserQuota();
  };

  onInputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  searchAction = () => {
    this.props.history.push(config.publicRoute + '/busqueda-historial');
  };

  openMenu = () => {
    this.setState({
      menuOpen: true,
    });
  };

  closeMenu = () => {
    this.setState({
      menuOpen: false,
    });
  };

  render() {
    const { selectedCustomer, menuOpen } = this.state;
    const {
      loading,
      user,
      transactionLoading,
      transactionsRecordResponse,
      userQuota,
    } = this.props;
    let userName = user
      ? `${user.userInfo.NOMBRES} ${user.userInfo.APELLIDOS}`
      : '';
    let quotas = userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
    let defaultQuota = quotas.length > 0 ? quotas[0] : '';
    let selectedQuota =
      selectedCustomer !== ''
        ? quotas.find((quota) => quota.AFILIADO_DOCUMENTO === selectedCustomer)
        : defaultQuota;
    let transactions =
      transactionsRecordResponse &&
      'TRANSACCIONES' in transactionsRecordResponse
        ? transactionsRecordResponse.TRANSACCIONES
        : [];
    return (
      <div className='record'>
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} />
        <Header
          search={false}
          searchAction={this.searchAction}
          menu
          openMenu={this.openMenu}
          tab={2}
        />
        <div className='container'>
          {(loading || transactionLoading) && <Loading />}
          <Col xs={12} className='client-container'>
            <Row>
              <Col xs='auto' className='full-center'>
                <img
                  className='client-icon'
                  src={clienticon}
                  alt='icono de cliente'
                />
              </Col>
              <Col>
                <Row>
                  <Row>
                    <Col xs={12} className='clien-key'>
                      Hola
                    </Col>
                    <Col xs={12} className='client-value'>
                      {userName.toLowerCase()}
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className='purchases-container'>
            <Col xs={12} className='card'>
              <Row className='purchases-title-container'>
                <Col xs='auto' className='full-center'>
                  <img
                    className='record-icon'
                    src={recordicon}
                    alt='icono de documento'
                  />
                </Col>
                <Col className='record-card_title'>
                  Respaldos con Covifactura
                </Col>
              </Row>
              {selectedQuota && (
                <Row className='purchases-title-container'>
                  {transactions.map((item, index) => {
                    return (
                      <Col key={index} xs={12} className='record-item'>
                        <Row>
                          <Col xs={12} className='record-item_name'>
                            {selectedQuota.AFILIADO_NOMBRES.toLowerCase()}
                          </Col>
                          <Col xs={12}>
                            <Row>
                              <Col xs={6} className='record-item_key'>
                                Fecha de compra
                              </Col>
                              <Col xs={6} className='record-item_value'>
                                {item.FECHA_COMPRA === ''
                                  ? ''
                                  : moment(item.FECHA_COMPRA).format(
                                      'DD/MM/YYYY'
                                    )}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12}>
                            <Row>
                              <Col xs={6} className='record-item_key'>
                                Fecha de pago
                              </Col>
                              <Col xs={6} className='record-item_value'>
                                {item.FECHA_VENCIMIENTO === ''
                                  ? ''
                                  : moment(item.FECHA_VENCIMIENTO).format(
                                      'DD/MM/YYYY'
                                    )}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12}>
                            <Row>
                              <Col xs={6} className='record-item_key'>
                                Tipo
                              </Col>
                              <Col xs={6} className='record-item_value'>
                                {item.TITULO.toLowerCase()}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12}>
                            <Row>
                              <Col xs={6} className='record-item_key'>
                                Estado deuda
                              </Col>
                              {item.ESTADO === 'PAGADA' && (
                                <Col xs={6} className='record-item_status'>
                                  Pagado
                                </Col>
                              )}
                              {item.ESTADO !== 'PAGADA' && (
                                <Col xs={6} className='record-item_status'>
                                  Vencido
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                        <Col xs={12} className='record-item_border' />
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Col>
          </Col>
        </div>
        <Chat />
        <Footer tab={2} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { globals, loading, userQuota, user } = state.user;
  let {
    loading: transactionLoading,
    currentCustomer,
    transactionsRecordResponse,
  } = state.transaction;
  return {
    globals,
    loading,
    userQuota,
    user,
    currentCustomer,
    transactionLoading,
    transactionsRecordResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Record);
