import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './establishment.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import blueclock from '../../assets/icons/blueclock.svg';
import whiteclock from '../../assets/icons/whiteclock.svg';
import whitebox from '../../assets/icons/whitebox.svg';
import bluebox from '../../assets/icons/bluebox.svg';
import locationbuttonicon from '../../assets/icons/locationbuttonicon.svg';
import logoEstablishment from '../../assets/images/iconindustry.svg';
import backarrow from '../../assets/icons/bigbackarrow.svg';
import Loading from '../loading';
import Menu from '../menu';
import TransactionItem from '../transaction-item';
import ClientHeader from '../client-header';
import moment from 'moment';
import 'moment/locale/es';
import config from '../../config';

moment.locale('es');

class Establishment extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      selectedCustomer: null,
      documentType: null,
      documentNumber: null,
      selectedTab: false,
    };
  }

  componentDidMount() {
    let params = this.props.match.params;
    let documentType = params.documentType;
    let documentNumber = params.documentNumber;
    this.setState(
      {
        documentType,
        documentNumber,
      },
      () => {
        this.loadData();
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userQuota !== this.props.userQuota) {
      if (nextProps.userQuota.CUPOS.length > 0) {
        const { documentType, documentNumber } = this.state;
        let selectedCustomer = nextProps.userQuota.CUPOS.find((quota) => {
          return (
            quota.AFILIADO_DOCUMENTO === documentNumber &&
            quota.AFILIADO_TIPO_DOCUMENTO === documentType
          );
        });
        this.getTransactionPurchases();
        this.setState({
          selectedCustomer,
        });
      }
    }
  }

  getTransactionPurchases = () => {
    const { documentType, documentNumber } = this.state;
    this.props.actions.getTransactionPurchases({
      customerDocumentType: documentType,
      customerDocument: documentNumber,
      transactionStatus: 'AL DIA,VENCIDO,RECLAMADA',
      branch: null,
    });
  };

  getTransactionsRecord = () => {
    const { documentType, documentNumber } = this.state;
    this.props.actions.getTransactionsRecord({
      customerDocumentType: documentType,
      customerDocument: documentNumber,
      transactionStatus: 'PAGADA,EFECTIVA',
      branch: null,
    });
  };

  loadData() {
    this.getUserQuota();
  }

  getUserQuota = () => {
    this.props.actions.getUser();
    this.props.actions.getUserQuota();
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    return thousand;
  }

  openMenu = () => {
    this.setState({
      menuOpen: true,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  closeMenu = () => {
    this.setState({
      menuOpen: false,
    });
  };

  changeTab = (tab) => {
    if (tab) {
      this.getTransactionsRecord();
    } else {
      this.getTransactionPurchases();
    }
    this.setState({
      selectedTab: tab,
    });
  };

  getDiferenceDays = (expirationDate) => {
    let momentDate = moment(expirationDate);
    let momentTodayDate = moment();
    let days = momentDate.diff(momentTodayDate, 'days');
    return [momentDate.format('DD/MMM/YYYY'), days];
  };

  goDetail = (item) => {
    this.props.actions.setTransaction({
      currentTransaction: item,
    });
    console.log('item', item);
    window.gtag('event', 'detalle', {
      event_category: item.ESTABLECIMIENTO,
      event_label: item.ESTABLECIMIENTO,
    });
    this.props.history.push(config.publicRoute + '/detalle');
  };

  goLocation = (item) => {
    const { documentType, documentNumber } = this.state;
    this.props.history.push(
      config.publicRoute +
        `/ubicacion-establecimiento/${documentType}/${documentNumber}`
    );
  };

  goDetail = (item) => {
    this.props.actions.setTransaction({
      currentTransaction: item,
    });
    window.gtag('event', 'detalle', {
      event_category: item.ESTABLECIMIENTO,
      event_label: item.ESTABLECIMIENTO,
    });
    this.props.history.push(config.publicRoute + '/detalle');
  };

  getItem = (item, index, record) => {
    let effective = item.ESTADO === 'EFECTIVA' || item.ESTADO === 'PAGADA';
    let dateArray = this.getDiferenceDays(
      item.FECHA_EXONERACION !== ''
        ? item.FECHA_EXONERACION
        : item.FECHA_VENCIMIENTO
    );
    let dateBuyArray = this.getDiferenceDays(item.FECHA_COMPRA);

    let days = parseInt(dateArray[1]);
    let dateText = dateArray[0];
    let dateBuyText = dateBuyArray[0];
    dateText = dateText.split('.').join('');
    dateBuyText = dateBuyText.split('.').join('');

    return (
      <TransactionItem
        item={item}
        dateText={dateText}
        dateBuyText={dateBuyText}
        days={days}
        effective={effective}
        stablishment
        goDetail={this.goDetail}
        key={index}
      />
    );
  };

  render() {
    const { selectedCustomer, menuOpen, selectedTab } = this.state;
    const {
      loading,
      loadingRecord,
      transactionsRecordResponse,
      loadingPurchases,
      transactionsPurchasesResponse,
    } = this.props;

    let transactionsPurchases =
      transactionsPurchasesResponse &&
      transactionsPurchasesResponse.TRANSACCIONES
        ? transactionsPurchasesResponse.TRANSACCIONES
        : [];
    let transactionsRecord =
      transactionsRecordResponse && transactionsRecordResponse.TRANSACCIONES
        ? transactionsRecordResponse.TRANSACCIONES
        : [];
    let quotaAvailable = selectedCustomer
      ? parseFloat(selectedCustomer.CUPO_DISPONIBLE)
      : 0;
    let quotaAssigned = selectedCustomer
      ? parseFloat(selectedCustomer.CUPO_ASIGNADO)
      : 0;
    let quotaUsed = quotaAssigned - quotaAvailable;
    let usedPercentaje = Math.ceil((quotaUsed / quotaAssigned) * 100);
    let availablePercentaje = Math.floor(
      (quotaAvailable / quotaAssigned) * 100
    );
    return (
      <div className='establishment'>
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} />
        <Header menu openMenu={this.openMenu} second />
        <div className='container'>
          {(loading || loadingRecord || loadingPurchases) && <Loading />}
          <ClientHeader />
          <Col xs={4} md={2}>
            <button
              className='back-button'
              onClick={(e) => {
                e.preventDefault();
                this.goBack();
              }}
            >
              <img
                className='header-tab-icon'
                src={backarrow}
                alt='icono de menú'
              />
              Volver
            </button>
          </Col>
          {selectedCustomer && (
            <Col xs={12} className='establishment-title-container'>
              <Row>
                <Col xs={12} className='establishment-logo-container'>
                  <Row className='full-center'>
                    <Col xs={8} md={4}>
                      <Row className='full-center no-wrap'>
                        <Col xs='auto'>
                          <div className='establishment-logo-img-container'>
                            <img
                              className='establishment-logo'
                              src={
                                selectedCustomer &&
                                selectedCustomer.LOGO_EMPRESA
                                  ? selectedCustomer.LOGO_EMPRESA
                                  : logoEstablishment
                              }
                              alt=''
                            />
                          </div>
                        </Col>
                        <Col className='establishment-title'>
                          {selectedCustomer.AFILIADO_NOMBRES.toLowerCase()}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      md={5}
                      className='establishments-container full-center hide-mobile'
                    >
                      <Col xs={12} className='card'>
                        <Row>
                          <Col
                            xs='auto'
                            className='establishments-percentage-type'
                          >
                            <div className='dark' />
                            Cupo utilizado
                          </Col>
                          <Col
                            xs='auto'
                            className='establishments-percentage-type'
                          >
                            <div className='light' />
                            Cupo disponible
                          </Col>
                          <Col
                            xs={12}
                            className='establishments-available-line-container'
                          >
                            <div className='establishments-vailable-line'>
                              <div className='establishments-percentage-label'>
                                ${this.getNumber(quotaAssigned)}
                              </div>
                              <div
                                className='establishments-used-line'
                                style={{ width: `${availablePercentaje}%` }}
                              />
                            </div>
                          </Col>
                          <Col className='establishment-percentage'>
                            ${this.getNumber(quotaUsed)}
                          </Col>
                          <Col className='establishment-percentage rigth'>
                            ${this.getNumber(quotaAvailable)}
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                    <Col xs={4} md={3}>
                      <button
                        className='location-button'
                        onClick={(e) => {
                          e.preventDefault();
                          this.goLocation();
                        }}
                      >
                        Ver mapa
                        <img
                          src={locationbuttonicon}
                          alt='logo establesimiento'
                        />
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  md={5}
                  className='establishments-container full-center hide-desktop'
                >
                  <Col xs={12} className='card'>
                    <Row>
                      <Col xs='auto' className='establishments-percentage-type'>
                        <div className='dark' />
                        Cupo utilizado
                      </Col>
                      <Col xs='auto' className='establishments-percentage-type'>
                        <div className='light' />
                        Cupo disponible
                      </Col>
                      <Col
                        xs={12}
                        className='establishments-available-line-container'
                      >
                        <div className='establishments-vailable-line'>
                          <div className='establishments-percentage-label'>
                            ${this.getNumber(quotaAssigned)}
                          </div>
                          <div
                            className='establishments-used-line'
                            style={{ width: `${availablePercentaje}%` }}
                          />
                        </div>
                      </Col>
                      <Col className='establishment-percentage'>
                        ${this.getNumber(quotaUsed)}
                      </Col>
                      <Col className='establishment-percentage rigth'>
                        ${this.getNumber(quotaAvailable)}
                      </Col>
                    </Row>
                  </Col>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className={'establishments-item-available'}>
                      Su cupo disponible es de:{' '}
                      <span>
                        ${this.getNumber(selectedCustomer.CUPO_DISPONIBLE)}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col xs='auto'></Col>
              </Row>
            </Col>
          )}
          {selectedCustomer && (
            <Col xs={12} className='establishment-container'>
              <Col xs={12} className='card'>
                {/* <Col xs={12}>
                  <Row className="hide-mobile establishment-title-container">
                    <Col xs="auto">
                      <img className="establishment-logo" src={logoEstablishment} alt='logo establesimiento' />
                    </Col>
                    <Col >
                      <Row>
                        <Col className="establishment-title">{selectedCustomer.AFILIADO_NOMBRES.toLowerCase()}</Col>
                        <Col xs="auto" className="establishment-title-key establishment-title-key-container">
                          Cupo: <span className="item-bold">${this.getNumber(selectedCustomer.CUPO_ASIGNADO)}</span>
                        </Col>
                        <Col xs="auto" className={parseInt(selectedCustomer.CUPO_DISPONIBLE) < 0 ? "establishment-title-value establishment-title-key-container danger" : "establishment-title-value establishment-title-key-container"}>
                          Disponible: <span className="item-bold">${this.getNumber(selectedCustomer.CUPO_DISPONIBLE)}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="auto">
                      <button
                        className="location-button"
                        onClick={(e) => {
                          e.preventDefault();
                          this.goLocation();
                        }}
                      >
                        Ver mapa
                    <img src={locationbuttonicon} alt='logo establesimiento' />
                      </button>
                    </Col>
                  </Row>
                </Col>
                 */}
                <Row className='establishment-items-container'>
                  <Col xs={12} className='tabs-container'>
                    <Col xs={12} className='tabs'>
                      <Row>
                        <Col
                          xs={!selectedTab ? true : 'auto'}
                          md={6}
                          className={
                            !selectedTab
                              ? 'tab-container tab-selected left'
                              : 'tab-container left'
                          }
                        >
                          <button
                            className='tab'
                            onClick={(e) => {
                              e.preventDefault();
                              this.changeTab(false);
                            }}
                          >
                            {!selectedTab && (
                              <img src={bluebox} alt='logo caja' />
                            )}
                            {selectedTab && (
                              <img src={whitebox} alt='logo caja' />
                            )}
                            {!selectedTab && <span>Compras respaldadas</span>}
                            {selectedTab && (
                              <span className='hide-mobile'>
                                Compras respaldadas
                              </span>
                            )}
                          </button>
                        </Col>
                        <Col
                          xs={selectedTab ? true : 'auto'}
                          md={6}
                          className={
                            selectedTab
                              ? 'tab-container tab-selected rigth'
                              : 'tab-container rigth'
                          }
                        >
                          <button
                            className='tab'
                            onClick={(e) => {
                              e.preventDefault();
                              this.changeTab(true);
                            }}
                          >
                            {!selectedTab && (
                              <img src={whiteclock} alt='logo reloj' />
                            )}
                            {selectedTab && (
                              <img src={blueclock} alt='logo reloj' />
                            )}
                            {!selectedTab && (
                              <span className='hide-mobile'>Historial</span>
                            )}
                            {selectedTab && <span>Historial</span>}
                          </button>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      className={
                        selectedTab ? 'tab-card rigth' : 'tab-card left'
                      }
                    >
                      {!selectedTab &&
                        transactionsPurchases.map((item, index) => {
                          return this.getItem(item, index);
                        })}
                      {selectedTab &&
                        transactionsRecord.map((item, index) => {
                          return this.getItem(item, index, true);
                        })}
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Col>
          )}
        </div>
        <Chat />
        <Footer tab={2} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { globals, loading, userQuota, user } = state.user;

  let {
    loadingRecord,
    transactionsRecordResponse,
    loadingPurchases,
    transactionsPurchasesResponse,
  } = state.transaction;

  return {
    globals,
    loading,
    userQuota,
    user,
    loadingRecord,
    transactionsRecordResponse,
    loadingPurchases,
    transactionsPurchasesResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Establishment);
