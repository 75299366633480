import {
  GET_GLOBALDATA_REQUEST,
  GET_GLOBALDATA_SUCCESS,
  GET_GLOBALDATA_FAILURE,
  GET_USERCONTACT_REQUEST,
  GET_USERCONTACT_SUCCESS,
  GET_USERCONTACT_FAILURE,
  GET_USERCONTACT_CLEAN,
  GET_TOKENLOGIN_REQUEST,
  GET_TOKENLOGIN_SUCCESS,
  GET_TOKENLOGIN_FAILURE,
  SEND_CODE_REQUEST,
  SEND_CODE_SUCCESS,
  SEND_CODE_FAILURE,
  SET_SELECTED_CONTACT,
  VALIDATE_CODE_REQUEST,
  VALIDATE_CODE_SUCCESS,
  VALIDATE_CODE_FAILURE,
  GET_USERQUOTA_REQUEST,
  GET_USERQUOTA_SUCCESS,
  GET_USERQUOTA_FAILURE,
  SET_USERDATA,
  GET_USERDATA,
} from '../action-types'

const initialState = {
  loading: false,
  loadingUserContacts: false,
  error: false,
  globals: false,
  userContacts: false,
  userLogin: false,
  // userContacts: {"VALIDACIONES":[{"TIPO":"CELULAR","DATO":"3165304630"},{"TIPO":"CELULAR","DATO":"3192183947"},{"TIPO":"EMAIL","DATO":"sandra.prada@covinoc.com"}],"TOKEN":"b3c869d931b4217f765620e3803a4253.5s8Wel3spC2oqooLMP0GbA=="},
  selectedContact: {TIPO: null, DATO: null},
  // selectedContact: {TIPO: "CELULAR", DATO: "3192183947"},
  userContactsError: false,
  codeSended: false,
  codeValidated: false,
  user: false,
  userQuota: false,
}

const users = (state = initialState, action) => {
  switch (action.type) {

    case GET_GLOBALDATA_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_GLOBALDATA_SUCCESS: {
      let globals = action.data
      return {
        ...state,
        globals: globals,
        error: false,
        loading: false
      }
    }

    case GET_GLOBALDATA_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case GET_USERCONTACT_REQUEST: {
      return {
        ...state,
        loadingUserContacts: true
      }
    }

    case GET_USERCONTACT_SUCCESS: {
      let userData = action.data
      return {
        ...state,
        userContacts: userData,
        userContactsError: false,
        loadingUserContacts: false
      }
    }
    case GET_USERCONTACT_FAILURE: {
      return {
        ...state,
        userContactsError: action.error,
        loadingUserContacts: false
      }
    }

    case GET_TOKENLOGIN_REQUEST: {
      return {
        ...state,
        loadingUserContacts: true
      }
    }

    case GET_TOKENLOGIN_SUCCESS: {
      let userData = action.data
      return {
        ...state,
        userLogin: userData,
        userContactsError: false,
        loadingUserContacts: false
      }
    }
    case GET_TOKENLOGIN_FAILURE: {
      return {
        ...state,
        userContactsError: action.error,
        loadingUserContacts: false
      }
    }

    case SET_SELECTED_CONTACT: {
      return {
        ...state,
        selectedContact: action.data,
        codeSended: false,
      }
    }

    case GET_USERCONTACT_CLEAN: {
      return {
          ...state,
          userContacts: false,
          userContactsError: false,
          codeSended: false,
          selectedContact: false,
          loading: false
        }
    }

    case SEND_CODE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case SEND_CODE_SUCCESS: {
      let codeSended = action.data
      return {
        ...state,
        codeSended,
        userError: false,
        loading: false
      }
    }
    case SEND_CODE_FAILURE: {
      return {
        ...state,
        userError: action.error,
        loading: false
      }
    }

    case VALIDATE_CODE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case VALIDATE_CODE_SUCCESS: {
      let codeValidated = action.data
      return {
        ...state,
        codeValidated,
        userError: false,
        loading: false
      }
    }
    case VALIDATE_CODE_FAILURE: {
      return {
        ...state,
        userError: action.error,
        loading: false
      }
    }

    case GET_USERQUOTA_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_USERQUOTA_SUCCESS: {
      let userQuota = action.data
      return {
        ...state,
        userQuota,
        error: false,
        loading: false
      }
    }
    case GET_USERQUOTA_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case SET_USERDATA: {
      return {
        ...state,
        error: {},
        loading: false
      }
    }

    case GET_USERDATA: {
      return {
        ...state,
        user: action.user,
        loading: false
      }
    }

    default:
      return state
  }
}

export default users
