// user
export const GET_GLOBALDATA_REQUEST = "GET_GLOBALDATA_REQUEST";
export const GET_GLOBALDATA_SUCCESS = "GET_GLOBALDATA_SUCCESS";
export const GET_GLOBALDATA_FAILURE = "GET_GLOBALDATA_FAILURE";

export const GET_TOKENLOGIN_REQUEST = "GET_TOKENLOGIN_REQUEST";
export const GET_TOKENLOGIN_SUCCESS = "GET_TOKENLOGIN_SUCCESS";
export const GET_TOKENLOGIN_FAILURE = "GET_TOKENLOGIN_FAILURE";

export const GET_USERCONTACT_REQUEST = "GET_USERCONTACT_REQUEST";
export const GET_USERCONTACT_SUCCESS = "GET_USERCONTACT_SUCCESS";
export const GET_USERCONTACT_FAILURE = "GET_USERCONTACT_FAILURE";

export const GET_USERCONTACT_CLEAN = "GET_USERCONTACT_CLEAN";

export const SET_SELECTED_CONTACT = "SET_SELECTED_CONTACT";

export const SET_USERDATA = "SET_USERDATA";

export const GET_USERDATA = "GET_USERDATA";

export const SEND_CODE_REQUEST = "SEND_CODE_REQUEST";
export const SEND_CODE_SUCCESS = "SEND_CODE_SUCCESS";
export const SEND_CODE_FAILURE = "SEND_CODE_FAILURE";

export const VALIDATE_CODE_REQUEST = "VALIDATE_CODE_REQUEST";
export const VALIDATE_CODE_SUCCESS = "VALIDATE_CODE_SUCCESS";
export const VALIDATE_CODE_FAILURE = "VALIDATE_CODE_FAILURE";

export const GET_USERQUOTA_REQUEST = "GET_USERQUOTA_REQUEST";
export const GET_USERQUOTA_SUCCESS = "GET_USERQUOTA_SUCCESS";
export const GET_USERQUOTA_FAILURE = "GET_USERQUOTA_FAILURE";

// transacctions
export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";

export const GET_TRANSACTIONS_RECORD_REQUEST =
  "GET_TRANSACTIONS_RECORD_REQUEST";
export const GET_TRANSACTIONS_RECORD_SUCCESS =
  "GET_TRANSACTIONS_RECORD_SUCCESS";
export const GET_TRANSACTIONS_RECORD_FAILURE =
  "GET_TRANSACTIONS_RECORD_FAILURE";

export const GET_TRANSACTIONS_PURCHASES_REQUEST =
  "GET_TRANSACTIONS_PURCHASES_REQUEST";
export const GET_TRANSACTIONS_PURCHASES_SUCCESS =
  "GET_TRANSACTIONS_PURCHASES_SUCCESS";
export const GET_TRANSACTIONS_PURCHASES_FAILURE =
  "GET_TRANSACTIONS_PURCHASES_FAILURE";

export const GET_TRANSACTIONS_SEARCH_REQUEST =
  "GET_TRANSACTIONS_SEARCH_REQUEST";
export const GET_TRANSACTIONS_SEARCH_SUCCESS =
  "GET_TRANSACTIONS_SEARCH_SUCCESS";
export const GET_TRANSACTIONS_SEARCH_FAILURE =
  "GET_TRANSACTIONS_SEARCH_FAILURE";

export const GET_COSTUMER_STATES_REQUEST = "GET_COSTUMER_STATES_REQUEST";
export const GET_COSTUMER_STATES_SUCCESS = "GET_COSTUMER_STATES_SUCCESS";
export const GET_COSTUMER_STATES_FAILURE = "GET_COSTUMER_STATES_FAILURE";

export const GET_COSTUMER_CITIES_REQUEST = "GET_COSTUMER_CITIES_REQUEST";
export const GET_COSTUMER_CITIES_SUCCESS = "GET_COSTUMER_CITIES_SUCCESS";
export const GET_COSTUMER_CITIES_FAILURE = "GET_COSTUMER_CITIES_FAILURE";

export const GET_COSTUMERS_LOCATION_REQUEST = "GET_COSTUMERS_LOCATION_REQUEST";
export const GET_COSTUMERS_LOCATION_SUCCESS = "GET_COSTUMERS_LOCATION_SUCCESS";
export const GET_COSTUMERS_LOCATION_FAILURE = "GET_COSTUMERS_LOCATION_FAILURE";

export const GET_PAYMENT_METHODS_REQUEST = "GET_PAYMENT_METHODS_REQUEST";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAILURE = "GET_PAYMENT_METHODS_FAILURE";

export const GET_PAYMENT_REFERENCE_REQUEST = "GET_PAYMENT_REFERENCE_REQUEST";
export const GET_PAYMENT_REFERENCE_SUCCESS = "GET_PAYMENT_REFERENCE_SUCCESS";
export const GET_PAYMENT_REFERENCE_FAILURE = "GET_PAYMENT_REFERENCE_FAILURE";

export const GET_PAYMENT_EXPORT_REFERENCE_REQUEST = "GET_PAYMENT_EXPORT_REFERENCE_REQUEST";
export const GET_PAYMENT_EXPORT_REFERENCE_SUCCESS = "GET_PAYMENT_EXPORT_REFERENCE_SUCCESS";
export const GET_PAYMENT_EXPORT_REFERENCE_FAILURE = "GET_PAYMENT_EXPORT_REFERENCE_FAILURE";

export const GET_PAYMENT_URL_REQUEST = "GET_PAYMENT_URL_REQUEST";
export const GET_PAYMENT_URL_SUCCESS = "GET_PAYMENT_URL_SUCCESS";
export const GET_PAYMENT_URL_FAILURE = "GET_PAYMENT_URL_FAILURE";

export const GET_PAYZEN_DATA_REQUEST = "GET_PAYZEN_DATA_REQUEST";
export const GET_PAYZEN_DATA_SUCCESS = "GET_PAYZEN_DATA_SUCCESS";
export const GET_PAYZEN_DATA_FAILURE = "GET_PAYZEN_DATA_FAILURE";

export const EXPORT_PDF_REQUEST = "EXPORT_PDF_REQUEST";
export const EXPORT_PDF_SUCCESS = "EXPORT_PDF_SUCCESS";
export const EXPORT_PDF_FAILURE = "EXPORT_PDF_FAILURE";

export const EXPORT_EMAIL_REQUEST = "EXPORT_EMAIL_REQUEST";
export const EXPORT_EMAIL_SUCCESS = "EXPORT_EMAIL_SUCCESS";
export const EXPORT_EMAIL_FAILURE = "EXPORT_EMAIL_FAILURE";

export const SET_TRANSACTION = "SET_TRANSACTION";
