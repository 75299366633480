import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './shops-search.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import locationitemicon from '../../assets/icons/locationitemicon.svg';
import item1img from '../../assets/images/IMG-04.png';
import item2img from '../../assets/images/IMG-05.png';
import item3img from '../../assets/images/IMG-06.png';
import config from '../../config';

class ShopsSearch extends Component {
  componentDidMount() {
    this.loadData();
  }

  loadData() {}

  closeAction = () => {
    this.props.history.push(config.publicRoute + '/comercios');
  };

  render() {
    return (
      <div className='shops-search'>
        <Header
          closeAction={this.closeAction}
          search={false}
          searching={true}
          searchPlaceholder='Buscar esteblecimiento'
          tab={100}
        />
        <div className='container'>
          <Col xs={12} className='shops-container'>
            <Col xs={12} className='card'>
              <Row className='shops-items-container'>
                <Col xs={12} className='shops-item'>
                  <Col xs={12} className='shops-item_border' />
                  <Row>
                    <Col xs={12}>
                      <button className='shop-item_button'>
                        <Row className='shop-item_button-container'>
                          <Col xs='auto'>
                            <img
                              className='item-img'
                              src={item1img}
                              alt='imagen de comercio'
                            />
                          </Col>
                          <Col className='shop-item_button-container-text'>
                            <Row>
                              <Col xs={12} className='shops-item_title'>
                                Ferretería Gomez
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Carrera 13 # 22 - 16 Local 122
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Horario: Lun - Sab de 8am - 7pm
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs='auto'
                            className='shop-item_button-container-text'
                          >
                            <img
                              className='location-icon'
                              src={locationitemicon}
                              alt='icono de ubicación'
                            />
                          </Col>
                        </Row>
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className='shops-item'>
                  <Col xs={12} className='shops-item_border' />
                  <Row>
                    <Col xs={12}>
                      <button className='shop-item_button'>
                        <Row className='shop-item_button-container'>
                          <Col xs='auto'>
                            <img
                              className='item-img'
                              src={item2img}
                              alt='imagen de comercio'
                            />
                          </Col>
                          <Col className='shop-item_button-container-text'>
                            <Row>
                              <Col xs={12} className='shops-item_title'>
                                Ferretería Gomez
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Carrera 13 # 22 - 16 Local 122
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Horario: Lun - Sab de 8am - 7pm
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs='auto'
                            className='shop-item_button-container-text'
                          >
                            <img
                              className='location-icon'
                              src={locationitemicon}
                              alt='icono de ubicación'
                            />
                          </Col>
                        </Row>
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className='shops-item'>
                  <Col xs={12} className='shops-item_border' />
                  <Row>
                    <Col xs={12}>
                      <button className='shop-item_button'>
                        <Row className='shop-item_button-container'>
                          <Col xs='auto'>
                            <img
                              className='item-img'
                              src={item3img}
                              alt='imagen de comercio'
                            />
                          </Col>
                          <Col className='shop-item_button-container-text'>
                            <Row>
                              <Col xs={12} className='shops-item_title'>
                                Ferretería Gomez
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Carrera 13 # 22 - 16 Local 122
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Horario: Lun - Sab de 8am - 7pm
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs='auto'
                            className='shop-item_button-container-text'
                          >
                            <img
                              className='location-icon'
                              src={locationitemicon}
                              alt='icono de ubicación'
                            />
                          </Col>
                        </Row>
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Col>
        </div>
        <Chat />
        <Footer tab={100} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopsSearch);
