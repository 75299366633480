import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from 'react-apexcharts';

import './establishments.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import arrownext from '../../assets/icons/arrownext.svg';
import logoEstablishment from '../../assets/images/iconindustry.svg';
import Loading from '../loading';
import Menu from '../menu';
import ClientHeader from '../client-header';
import config from '../../config';

const colors = [
  '#13619E',
  '#3598dc',
  '#19a1c3',
  '#11809f',
  '#0b617b',
  '#0e6655',
  '#138e75',
  '#239b57',
  '#2ecd72',
  '#7ae62b',
];

class Establishments extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.getUserQuota();
  }

  getUserQuota = () => {
    this.props.actions.getUser();
    this.props.actions.getUserQuota();
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    return thousand;
  }

  goDetail = (item) => {
    this.props.history.push(
      config.publicRoute +
        `/establecimientos/${item.AFILIADO_TIPO_DOCUMENTO}/${item.AFILIADO_DOCUMENTO}`
    );
  };

  openMenu = () => {
    this.setState({
      menuOpen: true,
    });
  };

  closeMenu = () => {
    this.setState({
      menuOpen: false,
    });
  };

  renderMobile = (item, index, repeatedcolors) => {
    return (
      <Col xs={12} md={6}>
        <div className='card establishment-item-color-container'>
          <button
            className='establishment-item-color'
            onClick={(e) => {
              e.preventDefault();
              this.goDetail(item);
            }}
          >
            <div
              className='establishment-item-color-point'
              style={{ background: repeatedcolors[index] }}
            />
            <div className='establishment-item-color-text-container'>
              <div className='establishment-item-color-text-title'>
                {item.AFILIADO_NOMBRES.toLowerCase()}
              </div>
              <div className='establishment-item-color-text-description'>
                ${this.getNumber(item.CUPO_ASIGNADO)}
              </div>
            </div>
            <div xs='auto'>
              <span className='establishment-item-show hide-mobile'>
                Ver mas
              </span>
              <img
                className='box-icon'
                src={arrownext}
                alt='icono de flecha siguiente'
              />
            </div>
          </button>
        </div>
      </Col>
    );
  };

  rowContainer = (item, renderButton) => {
    return (
      <Row className='establishments-item'>
        <Col xs='auto' className='hide-mobile'>
          <img
            className='establishment-logo'
            src={logoEstablishment}
            alt='logo establesimiento'
          />
        </Col>
        <Col className='hide-mobile'>
          <Row>
            <Col xs={12} md={4} className='establishments-item-title'>
              {item.AFILIADO_NOMBRES.toLowerCase()}
            </Col>
            <Col xs={12} md={4} className='establishments-item-quota'>
              Cupo:{' '}
              <span className='establishments-item-bold'>
                ${this.getNumber(item.CUPO_ASIGNADO)}
              </span>
            </Col>
            <Col xs={12} md={4} className='establishments-item-available'>
              Disponible:{' '}
              <span className='establishments-item-bold'>
                ${this.getNumber(item.CUPO_DISPONIBLE)}
              </span>
            </Col>
          </Row>
        </Col>
        {renderButton && (
          <Col xs='auto' className='hide-mobile'>
            <button
              className='details-button'
              onClick={(e) => {
                e.preventDefault();
                this.goDetail(item);
              }}
            >
              Ver más
            </button>
          </Col>
        )}
      </Row>
    );
  };

  render() {
    const { menuOpen } = this.state;
    const { userQuota, loading } = this.props;

    let quotas = userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
    let series = [];
    let labels = [];
    let quotaAvailable = 0;
    let quotaAssigned = 0;

    let repeatedcolors = [];
    if (quotas.length > 0) {
      let colorindex = 0;
      for (let index in quotas) {
        if (!(colorindex in colors)) {
          colorindex = 0;
        }
        repeatedcolors.push(colors[colorindex]);
        colorindex++;
        series.push(parseInt(quotas[index].CUPO_ASIGNADO));
        labels.push(quotas[index].AFILIADO_NOMBRES);
        quotaAvailable += parseFloat(quotas[index].CUPO_DISPONIBLE);
        quotaAssigned += parseFloat(quotas[index].CUPO_ASIGNADO);
      }
    }
    let quotaUsed = quotaAssigned - quotaAvailable;
    let usedPercentaje = Math.ceil((quotaUsed / quotaAssigned) * 100);
    let availablePercentaje = Math.floor(
      (quotaAvailable / quotaAssigned) * 100
    );
    let options = {
      legend: false,
      stroke: {
        colors: ['#F5F5F5'],
      },
      labels,
      colors,
      fontFamily: 'LatoWeb',
    };
    return (
      <div className='establishments'>
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} />
        <Header
          searchAction={this.searchAction}
          menu
          openMenu={this.openMenu}
          tab={2}
        />
        <div className='container'>
          {loading && <Loading />}
          <ClientHeader />
          <Col xs={12}>
            <Row>
              <Col xs={12} className='establishments-title-container'>
                <Row>
                  <Col xs={12} className='establishments-title'>
                    Establecimientos
                  </Col>
                  <Col xs={4}>
                    <Col className='establishments-title-border' />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <div className='donut'>
                  <div className='establishments_donut-title'>
                    <div className='establishments_donut-title_value'>
                      ${this.getNumber(quotaAssigned)}
                    </div>
                    <div className='establishments_donut-title_description'>
                      Pesos
                    </div>
                  </div>
                  <Chart
                    options={options}
                    series={series}
                    type='donut'
                    width='100%'
                  />
                </div>
              </Col>
              <Col
                xs={12}
                md={6}
                className='establishments-container full-center'
              >
                <Col xs={12} className='card'>
                  <Row>
                    <Col xs='auto' className='establishments-percentage-type'>
                      <div className='dark' />
                      Cupo utilizado
                    </Col>
                    <Col xs='auto' className='establishments-percentage-type'>
                      <div className='light' />
                      Cupo disponible
                    </Col>
                    <Col
                      xs={12}
                      className='establishments-available-line-container'
                    >
                      <div className='establishments-vailable-line'>
                        <div className='establishments-percentage-label'>
                          {usedPercentaje}%
                        </div>
                        <div className='establishments-percentage-label'>
                          {availablePercentaje}%
                        </div>
                        <div
                          className='establishments-used-line'
                          style={{ width: `${availablePercentaje}%` }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col xs={12} className='establishments-container'>
                <Row>
                  {quotas.map((item, index) => {
                    return this.renderMobile(item, index, repeatedcolors);
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
          {/*
            <Col xs={12} className="establishments-container hide-mobile">
            <Col xs={12} className="card">
            <Row className="hide-mobile establishments-title-container">
            <Col xs={12} className="establishments-title">
            Establecimientos
            </Col>
            <Col xs={4}>
            <Col className="establishments-title-border" />
            </Col>
            </Row>
            <Row className="establishments-items-container">
            {quotas.map((item, index) => {
            return (
            <Col key={index}  xs={12}>
            {this.rowContainer(item, true)}
            <Col xs={12} className="establishments-item-border"></Col>
            </Col>
            )
            })}
            </Row>
            </Col>
            </Col>
            */}
        </div>
        <Chat />
        <Footer tab={2} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { globals, loading, userQuota, user } = state.user;

  return {
    globals,
    loading,
    userQuota,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Establishments);
