import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './establishment-map.scss';
import * as actions from '../../redux/actions'
import { Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import ClientHeader from '../client-header';
import getrouteicon from '../../assets/icons/getrouteicon.svg';
import mylocationicon from '../../assets/icons/mylocationicon.svg';
import backarrow from '../../assets/icons/bigbackarrow.svg';
import Loading from '../loading';

declare var google;

class EstablishmentMap extends Component {

  constructor() {
    super();
    this.map = null;
    this.directionsService = null;
    this.directionsRenderer = null;
    this.markers = [];
    this.locationMarker = null;
    this.state = {
      loadingLocation: false,
      documentType: null,
      documentNumber: null,
      lat: null,
      long: null,
      mylat: null,
      mylong: null,
    }
  }

  componentDidMount() {
    // this.loadData()
    let params = this.props.match.params
    let documentType = params.documentType
    let documentNumber = params.documentNumber
    this.setState({
      documentType,
      documentNumber,
    }, () => {
      this.initMapPoint()
    });
  }

  initMapPoint() {
    console.log('entra a initMap')
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer();
    let lat = 4.6097100
    let long = -74.0817500
    let latLng = new google.maps.LatLng(lat, long)
    let mapOptions = {
      center: latLng,
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      disableDefaultUI: true,
    }
    this.map = new google.maps.Map(this.refs.map, mapOptions);
    this.directionsRenderer.setMap(this.map);
    let goldStar = {
      path: 'M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0',
      fillColor: '#FFFFFF',
      fillOpacity: 0.8,
      scale: 0.07,
      strokeColor: '#000000',
      strokeWeight: 3
    };
    this.locationMarker = new google.maps.Marker({
      icon: goldStar,
      position: latLng,
    });
    this.getMylocation();
  }

  getMylocation = () => {
    this.setState({
      loadingLocation: true
    }, () => {
      let lat = 4.6097100
      let long = -74.0817500
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          lat = position.coords.latitude;
          long = position.coords.longitude;
          let latlng = new google.maps.LatLng(lat, long);
          this.map.setCenter(latlng);
          this.locationMarker.setMap(this.map);
          this.locationMarker.setPosition(latlng);
          this.removeLoading();
          this.setState({
            mylat: lat,
            mylong: long,
          })
          this.getPoints(lat, long);
        });
      } else {
        let latlng = new google.maps.LatLng(lat, long);
        this.map.setCenter(latlng);
        this.locationMarker.setMap(this.map);
        this.locationMarker.setPosition(latlng);
        this.removeLoading();
        this.setState({
          mylat: lat,
          mylong: long,
        })
        this.getPoints(lat, long);
      }
    })
  }

  getPoints = (lat, long) => {
    const { documentType, documentNumber } = this.state;
    this.props.actions.getCostumersLocation({
      customerDocumentType: documentType,
      customerDocument: documentNumber,
      lat,
      long
    });
  }

  removeLoading = () => {
    this.setState({
      loadingLocation: false
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.costumersLocation !== this.props.costumersLocation) {
      this.setMarkers(nextProps.costumersLocation);
    }
  }

  setMarkers = (costumersLocation) => {
    const {
      mylat,
      mylong,
    } = this.state;
    let bounds = new google.maps.LatLngBounds();
    let loc = new google.maps.LatLng(mylat, mylong);
    bounds.extend(loc);
    this.clearMarkers();
    if ('DIRECCIONES3' in costumersLocation) {
      let locations = costumersLocation.DIRECCIONES3;
      for (let index in locations) {
        let lat = locations[index].LATITUD
        let long = locations[index].LONGITUD
        let latLng = new google.maps.LatLng(lat, long)
        let marker = new google.maps.Marker({
          position: latLng,
          map: this.map,
          title: locations[index].DIRECCION
        })
        marker.setMap(this.map)
        let loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
        bounds.extend(loc);

        // this.map.panTo(marker.getPosition())

        let infowindow = new google.maps.InfoWindow({
          content: locations[index].DIRECCION
        });

        marker.addListener('click', () => {
          let latLng = marker.getPosition();
          this.setState({
            lat: latLng.lat(),
            long: latLng.lng(),
          })
          infowindow.open(this.map, marker);
        });
        this.markers.push(marker);
      }
    }

    this.map.fitBounds(bounds);
    this.map.panToBounds(bounds);

  }

  clearMarkers = () => {
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
  }

  showRoute = () => {
    const {
      lat,
      long,
      mylat,
      mylong,
    } = this.state;
    this.setState({
      loadingLocation: true
    }, () => {
      this.directionsService.route(
        {
          origin: new google.maps.LatLng(mylat, mylong),
          destination: new google.maps.LatLng(lat, long),
          travelMode: 'DRIVING'
        }, (response, status) => {
          if (status === 'OK') {
            this.directionsRenderer.setDirections(response);
          } else {
            console.log('Directions request failed due to ' + status);
          }
          this.removeLoading();
        });
    })
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { loadingLocation, lat } = this.state;
    const { loading } = this.props;
    return (
      <div className="establishment-map">
        <Header menu openMenu={this.openMenu} second />
        <div className="container">
          {(loadingLocation || loading) && (
            <Loading />
          )}
          <ClientHeader />
          <Col xs={12} className="establishment-map-header-container">
            <Col xs={12} className="card">
              <div className="google-map" ref='map' />
              <button
                className="back-button"
                onClick={(e) => {
                  e.preventDefault();
                  this.goBack();
                }}
              >
                <img className="header-tab-icon" src={backarrow} alt='icono de menú' />
                  Volver
                </button>
              <div className="map-icons-container">
                {lat && (
                  <button
                    className="map-icon-button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showRoute();
                    }}
                  >
                    <img className="map-icon" src={getrouteicon} alt="icono mostrar ruta" />
                  </button>
                )}
                <button
                  className="map-icon-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.getMylocation();
                  }}
                >
                  <img className="map-icon" src={mylocationicon} alt="icono obtener ubicación" />
                </button>
              </div>
            </Col>
          </Col>
        </div>
        <Chat />
        <Footer tab={100} />
      </div>
    );
  }
}


const mapStateToProps = (state, props) => {
  let {
    loading,
    costumersLocation,
  } = state.transaction;
  return {
    loading,
    costumersLocation,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentMap);
