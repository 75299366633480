import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './resend-code.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import celullaricon from '../../assets/icons/celullaricon.svg';
import leftArrow from '../../assets/icons/left-arrow.svg';
import Loading from '../loading';
import LoginBackground from '../login-background';
import newlogo from '../../assets/logos/newlogo.svg';
import FloatingFooter from '../floating-footer';
import Chat from '../chat';
import config from '../../config';

class ResendCode extends Component {
  componentDidMount() {
    this.loadData();
  }

  loadData() {}

  goBack = () => {
    this.props.history.push(config.publicRoute + `/ingresar-codigo`);
  };

  onInputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  handleSubmit = () => {
    const { userContacts, selectedContact } = this.props;
    this.props.actions.setSelectedContact(selectedContact);
    this.props.actions.sendCode({
      contact: selectedContact.DATO,
      // contact: '3113653315',
      token: userContacts.TOKEN,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.codeSended) {
      this.props.history.push(config.publicRoute + `/ingresar-codigo`);
    }
  }

  render() {
    const { loading, loadingUserContacts } = this.props;
    return (
      <div className='resend-code login'>
        <Col>
          <Row className='full-center login-container'>
            <LoginBackground />
            <div className='login-logo-tablet hide-only-mobile hide-desktop'>
              <img className='' src={newlogo} alt='covifactura logo' />
            </div>
            <div className='mobile-side login-card'>
              <Col>
                <Row>
                  {(loading || loadingUserContacts) && <Loading />}
                  <Col
                    xs={12}
                    className='select-contact-card_icon-back-container'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goBack();
                    }}
                  >
                    <img
                      className='select-contact_icon-back'
                      src={leftArrow}
                      alt='error icono'
                    />
                    Volver
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className='resend-code-card_title'
                  >
                    ¿No recibió el código?
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className='resend-code-card_icon-container full-center'
                  >
                    <img
                      className='resend-code-icon'
                      src={celullaricon}
                      alt='error icono'
                    />
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className='resend-code-card_subtitle'
                  >
                    No se preocupe, reenviaremos un
                    <br />
                    nuevo código de seguridad a su
                    <br />
                    número de celular.
                  </Col>
                  <Col xs={{ span: 6, offset: 3 }} className='button-container'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleSubmit();
                      }}
                      className='button button-primary secundary-color'
                    >
                      Reenviar código
                    </button>
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </Col>
        <Chat home />
        <FloatingFooter />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    globals,
    loading,
    loadingUserContacts,
    userContacts,
    codeSended,
    selectedContact,
  } = state.user;
  return {
    globals,
    loading,
    loadingUserContacts,
    userContacts,
    codeSended,
    selectedContact,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendCode);
