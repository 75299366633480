import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const prefix = '/compradores';

const isLogued = (token, date) => {
  if (token && date) {
    const HOUR = 1000 * 60 * 60;
    const twoHoursLater = parseInt(date) + HOUR * 2;
    return Date.now() < twoHoursLater;
  } else {
    return false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLogued(
        localStorage.getItem('token'),
        localStorage.getItem('loginDate')
      ) ? (
        <Component {...props} />
      ) : (
        <Redirect from='*' to={prefix + '/login'} />
      )
    }
  />
);

export default PrivateRoute;
