const config = {
  //Producción Postgresql
  apiUrl: 'https://wsclifac.c0v.co/api/covifacturapi/servicios',
  covifactura: 'https://clientes.covifactura.com',
  hostUrl: 'https://clientes.covifactura.com/compradores',
  onlinePayment: 'https://clientes.covifactura.com/pagos/#/login/consultar-pago-en-linea',
  publicRoute: '/compradores',
  
  //Desarrollo Postgresql
  // apiUrl: 'https://desarrollo11.c0v.co/api/covifacturapi/servicios',
  // covifactura: 'http://desarrollo.covinoc.com:8082',
  // hostUrl: 'http://desarrollo.covinoc.com:8088/compradores',
  // onlinePayment: 'http://digitalcovinoc.com/felipe/compradores-payzen/#/login/consultar-pago-en-linea',

  // apiUrl: 'http://desarrollo.covinoc.com:8180/Compradores/servicios',
  // covifactura: 'http://desarrollo.covinoc.com:9097/',
  // hostUrl: 'http://desarrollo.covinoc.com:9108',
  // onlinePayment: 'http://digitalcovinoc.com/felipe/compradores-payzen/#/login/consultar-pago-en-linea',

  // apiUrl: 'https://clientes.covifactura.com:28443/Compradores/servicios',
  // covifactura: 'https://clientes.covifactura.com',
  // hostUrl: 'http://200.32.80.181:8088/compradores',
  // onlinePayment: 'https://clientes.covifactura.com/pagos/#/login/consultar-pago-en-linea',
};

export default config;
