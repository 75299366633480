import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import './footer.scss';
import { Navbar, Col, Row } from 'react-bootstrap';
// import hometabselected from '../../assets/icons/hometabselected.svg';
import purchases from '../../assets/icons/purchases.svg';
import purchasesblue from '../../assets/icons/purchasesblue.svg';
import shops from '../../assets/icons/shops.svg';
import shopsblue from '../../assets/icons/shopsblue.svg';
import covinocLogo from '../../assets/logos/logocovinoc.svg';
import config from '../../config';

// import chattabselected from '../../assets/icons/chattabselected.svg';
// import locationtabselected from '../../assets/icons/locationtabselected.svg';
// import recordtabselected from '../../assets/icons/recordtabselected.svg';
// import hometab from '../../assets/icons/hometab.svg';
// import chattabs from '../../assets/icons/chattabs.svg';
// import locationtab from '../../assets/icons/locationtab.svg';
// import recordtab from '../../assets/icons/recordtab.svg';

class Footer extends Component {
  goToHome = () => {
    this.props.history.push(config.publicRoute + '/');
  };

  goEstablishments = () => {
    this.props.history.push(config.publicRoute + '/establecimientos');
  };

  goToShops = () => {
    // this.props.history.push(config.publicRoute + '/comercios');
    this.props.history.push(config.publicRoute + '/ubicacion-comercios');
  };

  goToChat = () => {
    this.props.history.push(config.publicRoute + '/chat');
  };

  openChat() {
    window.$zopim.livechat.departments.filter('Mi Covinoc');
    window.$zopim.livechat.departments.setVisitorDepartment('Mi Covinoc');
    window.$zopim.livechat.window.show();
  }

  render() {
    // const { tab } = this.props;
    return (
      <div>
        <div className='footer-descktop hide-mobile'>
          <span className='menu-footer_text'>© 2020 | Un servicio de </span>
          <img
            className='menu-footer_logo'
            src={covinocLogo}
            alt='covinoc logo'
          />
        </div>
        {/*
          <Navbar fixed="bottom" className="footer hide-desktop">
          <Col xs={12}>
          <Row>
          <Col xs={6} className="footer_tab-icon-container">
          <button
          className="footer_tab"
          onClick={(e) => {
          e.preventDefault();
          this.goToHome();
          }}
          >
          { tab === 1 && (
          <img className="footer_tab-icon selected" src={purchasesblue} alt='Icono compras'/>
          )}
          { tab !== 1 && (
          <img className="footer_tab-icon" src={purchases} alt='Icono compras'/>
          )}
          <div className={tab === 1 ? "tab-text tab-text-selected":"tab-text"}>Compras</div>
          </button>
          </Col>
          <Col xs={6} className="footer_tab-icon-container">
          <button
          className="footer_tab"
          onClick={(e) => {
          e.preventDefault();
          this.goEstablishments();
          }}
          >
          { tab === 2 && (
          <img className="footer_tab-icon selected" src={shopsblue} alt='Icono de establesimientos'/>
          )}
          { tab !== 2 && (
          <img className="footer_tab-icon" src={shops} alt='Icono de establesimientos'/>
          )}
          <div className={tab === 2 ? "tab-text tab-text-selected":"tab-text"}>Establecimientos</div>
          </button>
          </Col>
          </Row>
          </Col>
          </Navbar>
          */}
      </div>
    );
  }
}

Footer.propTypes = {
  tab: PropTypes.number,
};

Footer.defaultProps = {
  tab: 1,
};

export default withRouter(Footer);
