import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../components/private-route';
import App from '../App';
import Onboarding from '../components/onboarding';
import Login from '../components/login';
import InvalidData from '../components/invalid-data';
import SelectContact from '../components/select-contact';
import OtherData from '../components/other-data';
import EnterCode from '../components/enter-code';
import ResendCode from '../components/resend-code';
import Home from '../components/home';
import Establishments from '../components/establishments';
import Establishment from '../components/establishment';
import EstablishmentMap from '../components/establishment-map';
import GlobalSearch from '../components/global-search';
import Detail from '../components/detail';
import Record from '../components/record';
import RecordSearch from '../components/record-search';
import Shops from '../components/shops';
import ShopsSearch from '../components/shops-search';
import Location from '../components/location';
import Chat from '../components/chat';

const prefix = '/compradores';

const AppRoutes = () => (
  <App>
    <Switch>
      <Route exact path={prefix + '/onboarding'} component={Onboarding} />
      <Route exact path={prefix + '/login'} component={Login} />
      <Route
        exact
        path={prefix + '/datos-erroneos/:documentType/:documentNumber'}
        component={InvalidData}
      />
      <Route
        exact
        path={prefix + '/seleccionar-contacto'}
        component={SelectContact}
      />
      <Route exact path={prefix + '/no-son-sus-datos'} component={OtherData} />
      <Route exact path={prefix + '/ingresar-codigo'} component={EnterCode} />
      <Route exact path={prefix + '/reenviar-codigo'} component={ResendCode} />
      <PrivateRoute exact path={prefix + '/'} component={Home} />
      <PrivateRoute
        exact
        path={prefix + '/establecimientos'}
        component={Establishments}
      />
      <PrivateRoute
        exact
        path={prefix + '/establecimientos/:documentType/:documentNumber'}
        component={Establishment}
      />
      <PrivateRoute
        exact
        path={
          prefix + '/ubicacion-establecimiento/:documentType/:documentNumber'
        }
        component={EstablishmentMap}
      />
      <PrivateRoute exact path={prefix + '/buscar'} component={GlobalSearch} />
      <PrivateRoute exact path={prefix + '/detalle'} component={Detail} />
      <PrivateRoute exact path={prefix + '/historial'} component={Record} />
      <PrivateRoute
        exact
        path={prefix + '/busqueda-historial'}
        component={RecordSearch}
      />
      <PrivateRoute exact path={prefix + '/comercios'} component={Shops} />
      <PrivateRoute
        exact
        path={prefix + '/busqueda-comercios'}
        component={ShopsSearch}
      />
      <PrivateRoute
        exact
        path={prefix + '/ubicacion-comercios'}
        component={Location}
      />
      <PrivateRoute exact path={prefix + '/chat'} component={Chat} />
      <Redirect from='*' to={prefix + '/'} />
    </Switch>
  </App>
);

export default AppRoutes;
