import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import './chat.scss';
import chatNew from '../../assets/icons/chatnew.png';
import whiteheadphones from '../../assets/icons/whiteheadphones.svg';
import wpicon from '../../assets/icons/wpicon.png';

class Chat extends Component {
  state = {
    isOpen: false,
  };

  openOptions = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  // openChat = () => {
  //   const { home } = this.props;

  //   if(home) {
  //     window.gtag('event', 'chat_linea', { 'event_category': 'sin-login', 'event_label': 'sin-login' });
  //   }

  //   // window.$zopim.livechat.departments.filter("Mi Covinoc");
  //   // window.$zopim.livechat.departments.setVisitorDepartment('Mi Covinoc');
  //   // window.$zopim.livechat.window.show();

  //   window.lh_inst.showStartWindow()
  // }

  openWp = () => {
    const { home } = this.props;
    if (home) {
      window.gtag('event', 'chat_whapp', {
        event_category: 'sin-login',
        event_label: 'sin-login',
      });
    } else {
      let userJson = JSON.parse(localStorage.getItem('userDocument'));
      let idPais = '1';
      window.gtag('event', 'chat_whapp', {
        event_category: `${userJson.documentType}:${userJson.documentNumber}:${idPais}`,
        event_label: userJson.documentNumber,
      });
    }
    window.open(
      'https://api.whatsapp.com/send?phone=573102222377',
      '_newtab'
    );
  };

  render() {
    const { home, mobile } = this.props;
    const { isOpen } = this.state;
    let chatMobile = mobile ? 'chat' : 'chat';
    return (
      <div className={home ? chatMobile + ' chat-home' : chatMobile}>
        {/* <div
          onClick={(e) => {
            e.preventDefault()
            this.openChat()
          }}
          className={`chat-new_items-container chat-new_item-chat${isOpen ? ' is-open' : ''}`}>
          <div>
            Chat
                </div>
          <img src={chatNew} alt="chat icon" />

        </div> */}
        <div
          onClick={(e) => {
            e.preventDefault();
            this.openWp();
          }}
          className={`chat-new_items-container chat-new_item-wp${
            isOpen ? ' is-open' : ''
          }`}
        >
          <div>Whatsapp</div>
          <img src={wpicon} alt='chat icon' />
        </div>
        <div
          onClick={(e) => {
            e.preventDefault();
            this.openWp();
          }}
          className='chat-new_options-cotainer'
        >
          <div className='chat-new_options-icon'>
            <img src={whiteheadphones} alt='chat icon' />
          </div>
          <div>¿Necesita ayuda?</div>
        </div>
      </div>
    );
  }
}

Chat.propTypes = {
  home: PropTypes.bool,
};

Chat.defaultProps = {
  home: false,
};

export default Chat;
