import React, { Component } from 'react';

import './loading.scss';
import loading from '../../assets/icons/loadingblue.svg';

class Loading extends Component {

  render() {
    return (
      <div className="loading" >
        <img src={loading} className="loader" alt="loading" />
      </div>
    );
  }
}

export default Loading;
