import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './shops.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import clienticon from '../../assets/icons/clienticon.svg';
import shopicon from '../../assets/icons/shopicon.svg';
import locationitemicon from '../../assets/icons/locationitemicon.svg';
import item1img from '../../assets/images/IMG-04.png';
import item2img from '../../assets/images/IMG-05.png';
import item3img from '../../assets/images/IMG-06.png';
import config from '../../config';

const economic = [
  { id: 1, name: 'Ferreterias' },
  { id: 2, name: 'Tiendas' },
  { id: 3, name: 'Comercios' },
];

const departments = [
  { id: 1, name: 'Cundinamarca' },
  { id: 2, name: 'Antioquia' },
  { id: 3, name: 'Valle del cauca' },
];

const cities = [
  { id: 1, name: 'Bogotá' },
  { id: 2, name: 'Medellín' },
  { id: 3, name: 'Cali' },
];

class Shops extends Component {
  constructor() {
    super();
    this.state = {
      selectedCity: '',
      selectedDepartment: '',
      selectedEconomic: '',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {}

  onInputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  searchAction = () => {
    this.props.history.push(config.publicRoute + '/busqueda-comercios');
  };

  goShop = () => {
    this.props.history.push(config.publicRoute + '/ubicacion-comercios');
  };

  render() {
    const { selectedCity, selectedDepartment, selectedEconomic } = this.state;
    return (
      <div className='shops'>
        <Header search={true} searchAction={this.searchAction} tab={3} />
        <div className='container'>
          <Col xs={12} className='client-container'>
            <Row>
              <Col xs='auto' className='full-center'>
                <img
                  className='client-icon'
                  src={clienticon}
                  alt='icono de cliente'
                />
              </Col>
              <Col>
                <Row>
                  <Row>
                    <Col xs={12} className='clien-key'>
                      Hola
                    </Col>
                    <Col xs={12} className='client-value'>
                      Juan Camilo Gomez
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className='shops-container'>
            <Col xs={12} className='card'>
              <Row className='shops-title-container'>
                <Col xs='auto' className='full-center'>
                  <img
                    className='shops-icon'
                    src={shopicon}
                    alt='icono de documento'
                  />
                </Col>
                <Col className='shops-card_title'>
                  Comercios con Covifactura
                </Col>
                <Col xs={12} className='shops-card_subtitle'>
                  Seleccione nuestros filtros de <br />
                  búsqueda
                </Col>
              </Row>
              <Col xs={12}>
                <Row className='shops-selects-container'>
                  <Col xs={7}>
                    <Row>
                      <Col xs={12} className='client-commerce'>
                        <Row>
                          <Col xs={12} className='clien-key'>
                            Departamento
                          </Col>
                          <Col xs={12}>
                            <select
                              name='selectedDepartment'
                              className='client-value-select'
                              id='selectedDepartment'
                              onChange={(event) => {
                                this.onInputChange(event, 'selectedDepartment');
                              }}
                              value={selectedDepartment}
                            >
                              <option value=''></option>
                              {departments.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className='client-commerce'>
                        <Row>
                          <Col xs={12} className='clien-key'>
                            Sector económico
                          </Col>
                          <Col xs={12}>
                            <select
                              name='selectedCity'
                              className='client-value-select'
                              id='selectedCity'
                              onChange={(event) => {
                                this.onInputChange(event, 'selectedCity');
                              }}
                              value={selectedCity}
                            >
                              <option value=''></option>
                              {economic.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={5}>
                    <Row>
                      <Col xs={12} className='client-commerce'>
                        <Row>
                          <Col xs={12} className='clien-key'>
                            Ciudad
                          </Col>
                          <Col xs={12}>
                            <select
                              name='selectedEconomic'
                              className='client-value-select'
                              id='selectedEconomic'
                              onChange={(event) => {
                                this.onInputChange(event, 'selectedEconomic');
                              }}
                              value={selectedEconomic}
                            >
                              <option value=''></option>
                              {cities.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Row className='shops-items-container'>
                <Col xs={12} className='shops-item'>
                  <Col xs={12} className='shops-item_border' />
                  <Row>
                    <Col xs={12}>
                      <button
                        className='shop-item_button'
                        onClick={(e) => {
                          e.preventDefault();
                          this.goShop();
                        }}
                      >
                        <Row className='shop-item_button-container'>
                          <Col xs='auto'>
                            <img
                              className='item-img'
                              src={item1img}
                              alt='imagen de comercio'
                            />
                          </Col>
                          <Col className='shop-item_button-container-text'>
                            <Row>
                              <Col xs={12} className='shops-item_title'>
                                Ferretería Gomez
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Carrera 13 # 22 - 16 Local 122
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Horario: Lun - Sab de 8am - 7pm
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs='auto'
                            className='shop-item_button-container-text'
                          >
                            <img
                              className='location-icon'
                              src={locationitemicon}
                              alt='icono de ubicación'
                            />
                          </Col>
                        </Row>
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className='shops-item'>
                  <Col xs={12} className='shops-item_border' />
                  <Row>
                    <Col xs={12}>
                      <button
                        className='shop-item_button'
                        onClick={(e) => {
                          e.preventDefault();
                          this.goShop();
                        }}
                      >
                        <Row className='shop-item_button-container'>
                          <Col xs='auto'>
                            <img
                              className='item-img'
                              src={item2img}
                              alt='imagen de comercio'
                            />
                          </Col>
                          <Col className='shop-item_button-container-text'>
                            <Row>
                              <Col xs={12} className='shops-item_title'>
                                Ferretería Gomez
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Carrera 13 # 22 - 16 Local 122
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Horario: Lun - Sab de 8am - 7pm
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs='auto'
                            className='shop-item_button-container-text'
                          >
                            <img
                              className='location-icon'
                              src={locationitemicon}
                              alt='icono de ubicación'
                            />
                          </Col>
                        </Row>
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className='shops-item'>
                  <Col xs={12} className='shops-item_border' />
                  <Row>
                    <Col xs={12}>
                      <button
                        className='shop-item_button'
                        onClick={(e) => {
                          e.preventDefault();
                          this.goShop();
                        }}
                      >
                        <Row className='shop-item_button-container'>
                          <Col xs='auto'>
                            <img
                              className='item-img'
                              src={item3img}
                              alt='imagen de comercio'
                            />
                          </Col>
                          <Col className='shop-item_button-container-text'>
                            <Row>
                              <Col xs={12} className='shops-item_title'>
                                Ferretería Gomez
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Carrera 13 # 22 - 16 Local 122
                              </Col>
                              <Col xs={12} className='shops-item_subtitle'>
                                Horario: Lun - Sab de 8am - 7pm
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs='auto'
                            className='shop-item_button-container-text'
                          >
                            <img
                              className='location-icon'
                              src={locationitemicon}
                              alt='icono de ubicación'
                            />
                          </Col>
                        </Row>
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Col>
        </div>
        <Chat />
        <Footer tab={3} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shops);
