import * as transactionService from "../services";
import {
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_RECORD_REQUEST,
  GET_TRANSACTIONS_RECORD_SUCCESS,
  GET_TRANSACTIONS_RECORD_FAILURE,
  GET_TRANSACTIONS_PURCHASES_REQUEST,
  GET_TRANSACTIONS_PURCHASES_SUCCESS,
  GET_TRANSACTIONS_PURCHASES_FAILURE,
  GET_TRANSACTIONS_SEARCH_REQUEST,
  GET_TRANSACTIONS_SEARCH_SUCCESS,
  GET_TRANSACTIONS_SEARCH_FAILURE,
  GET_COSTUMER_STATES_REQUEST,
  GET_COSTUMER_STATES_SUCCESS,
  GET_COSTUMER_STATES_FAILURE,
  GET_COSTUMER_CITIES_REQUEST,
  GET_COSTUMER_CITIES_SUCCESS,
  GET_COSTUMER_CITIES_FAILURE,
  GET_COSTUMERS_LOCATION_REQUEST,
  GET_COSTUMERS_LOCATION_SUCCESS,
  GET_COSTUMERS_LOCATION_FAILURE,
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_REFERENCE_REQUEST,
  GET_PAYMENT_REFERENCE_SUCCESS,
  GET_PAYMENT_REFERENCE_FAILURE,
  GET_PAYMENT_EXPORT_REFERENCE_REQUEST,
  GET_PAYMENT_EXPORT_REFERENCE_SUCCESS,
  GET_PAYMENT_EXPORT_REFERENCE_FAILURE,
  GET_PAYMENT_URL_REQUEST,
  GET_PAYMENT_URL_SUCCESS,
  GET_PAYMENT_URL_FAILURE,
  GET_PAYZEN_DATA_REQUEST,
  GET_PAYZEN_DATA_SUCCESS,
  GET_PAYZEN_DATA_FAILURE,
  EXPORT_PDF_REQUEST,
  EXPORT_PDF_SUCCESS,
  EXPORT_PDF_FAILURE,
  EXPORT_EMAIL_REQUEST,
  EXPORT_EMAIL_SUCCESS,
  EXPORT_EMAIL_FAILURE,
  SET_TRANSACTION,
} from "../action-types";

export const getTransactions = ({
  customerDocumentType,
  customerDocument,
  transactionStatus,
  branch,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getTransactions({
        customerDocumentType,
        customerDocument,
        transactionStatus,
        branch,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_TRANSACTIONS_REQUEST };
  }
  function success(data) {
    return { type: GET_TRANSACTIONS_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_TRANSACTIONS_FAILURE, error };
  }
};

export const getTransactionPurchases = ({
  customerDocumentType,
  customerDocument,
  transactionStatus,
  branch,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getTransactions({
        customerDocumentType,
        customerDocument,
        transactionStatus,
        branch,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_TRANSACTIONS_PURCHASES_REQUEST };
  }
  function success(data) {
    return { type: GET_TRANSACTIONS_PURCHASES_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_TRANSACTIONS_PURCHASES_FAILURE, error };
  }
};

export const getTransactionsRecord = ({
  customerDocumentType,
  customerDocument,
  transactionStatus,
  branch,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getTransactions({
        customerDocumentType,
        customerDocument,
        transactionStatus,
        branch,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_TRANSACTIONS_RECORD_REQUEST };
  }
  function success(data) {
    return { type: GET_TRANSACTIONS_RECORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_TRANSACTIONS_RECORD_FAILURE, error };
  }
};

export const getTransactionsSearch = ({
  customerDocumentType,
  customerDocument,
  transactionStatus,
  branch,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getTransactions({
        customerDocumentType,
        customerDocument,
        transactionStatus,
        branch,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_TRANSACTIONS_SEARCH_REQUEST };
  }
  function success(data) {
    return { type: GET_TRANSACTIONS_SEARCH_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_TRANSACTIONS_SEARCH_FAILURE, error };
  }
};

export const getCostumerStates = ({
  customerDocumentType,
  customerDocument,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getCostumerStates({ customerDocumentType, customerDocument })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_COSTUMER_STATES_REQUEST };
  }
  function success(data) {
    return { type: GET_COSTUMER_STATES_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_COSTUMER_STATES_FAILURE, error };
  }
};

export const getCostumerCities = ({
  customerDocumentType,
  customerDocument,
  department,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getCostumerCities({ customerDocumentType, customerDocument, department })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_COSTUMER_CITIES_REQUEST };
  }
  function success(data) {
    return { type: GET_COSTUMER_CITIES_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_COSTUMER_CITIES_FAILURE, error };
  }
};

export const getCostumersLocation = ({
  customerDocumentType,
  customerDocument,
  lat,
  long,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getCostumersLocation({
        customerDocumentType,
        customerDocument,
        lat,
        long,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_COSTUMERS_LOCATION_REQUEST };
  }
  function success(data) {
    return { type: GET_COSTUMERS_LOCATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_COSTUMERS_LOCATION_FAILURE, error };
  }
};

export const getPaymentMethods = ({ documentType, documentNumber }) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getPaymentMethods({
        documentType,
        documentNumber,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_PAYMENT_METHODS_REQUEST };
  }
  function success(data) {
    return { type: GET_PAYMENT_METHODS_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_PAYMENT_METHODS_FAILURE, error };
  }
};

export const getPayzenReference = ({
  obligation,
  documentType,
  documentNumber,
  amount,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getReference({
        obligation,
        documentType,
        documentNumber,
        online: true,
        amount,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_PAYMENT_REFERENCE_REQUEST };
  }
  function success(data) {
    return { type: GET_PAYMENT_REFERENCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_PAYMENT_REFERENCE_FAILURE, error };
  }
};

export const getExportReference = ({
  obligation,
  documentType,
  documentNumber,
  amount,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getReference({
        obligation,
        documentType,
        documentNumber,
        online: false,
        amount,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_PAYMENT_EXPORT_REFERENCE_REQUEST };
  }
  function success(data) {
    return { type: GET_PAYMENT_EXPORT_REFERENCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_PAYMENT_EXPORT_REFERENCE_FAILURE, error };
  }
};

export const getUrlBaloto = ({
  obligation,
  documentType,
  documentNumber,
  amount,
  taxAmount,
  expirationDate,
  productCode,
  customerName,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getUrlBaloto({
        obligation,
        documentType,
        documentNumber,
        amount,
        taxAmount,
        expirationDate,
        productCode,
        customerName,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_PAYMENT_URL_REQUEST };
  }
  function success(data) {
    return { type: GET_PAYMENT_URL_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_PAYMENT_URL_FAILURE, error };
  }
};

export const getPayzenData = ({
  documentType,
  documentNumber,
  amount,
  reference,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getPayzenData({
        documentType,
        documentNumber,
        amount,
        reference,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_PAYZEN_DATA_REQUEST };
  }
  function success(data) {
    return { type: GET_PAYZEN_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_PAYZEN_DATA_FAILURE, error };
  }
};

export const exportPaymentPdf = ({
  documentType,
  documentNumber,
  amount,
  reference,
  obligation,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .exportPaymentPdf({
        documentType,
        documentNumber,
        amount,
        reference,
        obligation,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: EXPORT_PDF_REQUEST };
  }
  function success(data) {
    return { type: EXPORT_PDF_SUCCESS, data };
  }
  function failure(error) {
    return { type: EXPORT_PDF_FAILURE, error };
  }
};

export const exportPaymentEmail = ({
  documentType,
  documentNumber,
  amount,
  reference,
  obligation,
  email
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .exportPaymentEmail({
        documentType,
        documentNumber,
        amount,
        reference,
        obligation,
        email
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: EXPORT_EMAIL_REQUEST };
  }
  function success(data) {
    return { type: EXPORT_EMAIL_SUCCESS, data };
  }
  function failure(error) {
    return { type: EXPORT_EMAIL_FAILURE, error };
  }
};

export const setTransaction = (transaction) => {
  return (dispatch) => {
    dispatch(success(transaction));
  };

  function success(data) {
    return { type: SET_TRANSACTION, data };
  }
};
