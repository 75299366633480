import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './invalid-data.scss';
import * as actions from '../../redux/actions';
import { Row, Col, Button } from 'react-bootstrap';
import loginlogo from '../../assets/logos/loginlogo.svg';
import warningicon from '../../assets/icons/warningicon.svg';
import backarrow from '../../assets/icons/backarrow.svg';
import config from '../../config';

class InvalidData extends Component {
  constructor() {
    super();
    this.state = {
      documentType: '',
      documentNumber: '',
    };
  }

  componentDidMount() {
    let params = this.props.match.params;
    let documentType = params.documentType;
    let documentNumber = params.documentNumber;
    this.setState({
      documentType,
      documentNumber,
    });
    this.loadData();
  }

  loadData() {}

  onInputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  goBack = () => {
    this.props.history.push(config.publicRoute + `/login`);
  };

  render() {
    const { documentType, documentNumber } = this.state;
    return (
      <div className='invalid-data'>
        <Col>
          <Row>
            <Col md={8} className='loginbackground' />
            <Col md={4} xs={12}>
              <Row>
                <Col xs={12} className='full-center'>
                  <img
                    className='invalid-data-logo'
                    src={loginlogo}
                    alt='covifactura logo'
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 8, offset: 2 }}
                  className='invalid-data-card'
                >
                  <Col
                    xs={12}
                    onClick={(e) => {
                      e.preventDefault();
                      this.goBack();
                    }}
                    className='invalid-data-card_icon-back-container'
                  >
                    <img
                      className='invalid-data_icon-back'
                      src={backarrow}
                      alt='error icono'
                    />
                  </Col>
                  <Col xs={12} className='invalid-data-card_title'>
                    Datos invalidos
                  </Col>
                  <Col
                    xs={12}
                    className='invalid-data-card_icon-container full-center'
                  >
                    <img
                      className='invalid-data-icon'
                      src={warningicon}
                      alt='error icono'
                    />
                  </Col>
                  <Col xs={12} className='invalid-data-card_subtitle'>
                    La{' '}
                    <span className='bold'>
                      {documentType} {documentNumber}
                    </span>{' '}
                    no se encuentra
                    <br />
                    registrado en nuestro sistema.
                    <br />
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className='button-container'
                  >
                    <Button className='button-primary' variant='primary' block>
                      ESTUDIO CLIENTE
                    </Button>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvalidData);
