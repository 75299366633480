import * as userService from "../services";
import {
  GET_GLOBALDATA_REQUEST,
  GET_GLOBALDATA_SUCCESS,
  GET_GLOBALDATA_FAILURE,
  GET_TOKENLOGIN_REQUEST,
  GET_TOKENLOGIN_SUCCESS,
  GET_TOKENLOGIN_FAILURE,
  GET_USERCONTACT_REQUEST,
  GET_USERCONTACT_SUCCESS,
  GET_USERCONTACT_FAILURE,
  GET_USERCONTACT_CLEAN,
  SEND_CODE_REQUEST,
  SEND_CODE_SUCCESS,
  SEND_CODE_FAILURE,
  VALIDATE_CODE_REQUEST,
  VALIDATE_CODE_SUCCESS,
  VALIDATE_CODE_FAILURE,
  GET_USERQUOTA_REQUEST,
  GET_USERQUOTA_SUCCESS,
  GET_USERQUOTA_FAILURE,
  SET_SELECTED_CONTACT,
  SET_USERDATA,
  GET_USERDATA,
} from "../action-types";

export const getAppGlobals = (reload) => {
  return (dispatch) => {
    dispatch(request());
    let globals = JSON.parse(localStorage.getItem("globals"));
    if (!reload && globals) {
      dispatch(success(globals));
    } else {
      userService.getGlobals().then(
        (data) => {
          localStorage.setItem("globals", JSON.stringify(data));
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
    }
  };

  function request() {
    return { type: GET_GLOBALDATA_REQUEST };
  }
  function success(data) {
    return { type: GET_GLOBALDATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_GLOBALDATA_FAILURE, error };
  }
};

export const getUserContact = ({ documentType, documentNumber }) => {
  return (dispatch) => {
    dispatch(request());
    localStorage.setItem(
      "userDocument",
      JSON.stringify({ documentType, documentNumber })
    );
    userService.getUserContact({ documentType, documentNumber }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_USERCONTACT_REQUEST };
  }
  function success(data) {
    return { type: GET_USERCONTACT_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_USERCONTACT_FAILURE, error };
  }
};
export const tokenLogin = ({ documentType, documentNumber, loginToken }) => {
  return (dispatch) => {
    dispatch(request());
    localStorage.setItem(
      "userDocument",
      JSON.stringify({ documentType, documentNumber })
    );
    userService
      .tokenLogin({
        documentType,
        documentNumber,
        loginToken,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_TOKENLOGIN_REQUEST };
  }
  function success(data) {
    return { type: GET_TOKENLOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_TOKENLOGIN_FAILURE, error };
  }
};

export const setSelectedContact = (data) => {
  return (dispatch) => {
    dispatch(request(data));
  };

  function request(data) {
    return { type: SET_SELECTED_CONTACT, data };
  }
};

export const sendCode = ({ contact, token }) => {
  return (dispatch) => {
    dispatch(request());
    userService.sendCode({ contact, token }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: SEND_CODE_REQUEST };
  }
  function success(data) {
    return { type: SEND_CODE_SUCCESS, data };
  }
  function failure(error) {
    return { type: SEND_CODE_FAILURE, error };
  }
};

export const validateCode = ({ code, token }) => {
  return (dispatch) => {
    dispatch(request());
    userService.validateCode({ code, token }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: VALIDATE_CODE_REQUEST };
  }
  function success(data) {
    return { type: VALIDATE_CODE_SUCCESS, data };
  }
  function failure(error) {
    return { type: VALIDATE_CODE_FAILURE, error };
  }
};

export const getUserQuota = () => {
  return (dispatch) => {
    dispatch(request());
    userService.getUserQuota().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_USERQUOTA_REQUEST };
  }
  function success(data) {
    return { type: GET_USERQUOTA_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_USERQUOTA_FAILURE, error };
  }
};

export const cleanUserContact = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: GET_USERCONTACT_CLEAN };
  }
};

export const setUser = ({ token, userInfo }) => {
  let now = Date.now();
  localStorage.setItem("loginDate", now);
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify({ token, userInfo }));
  return (dispatch) => {
    dispatch(setUser());
  };
  function setUser() {
    return { type: SET_USERDATA };
  }
};

export const getUser = () => {
  // let user = {"userEmail":"CEGM@PRUEBA.COM","password":"PRUEBAS","token":"60a87a2c73b91115a79d498974db287e","userInfo":{"DOCUMENTO":"8110286501","ROL_USUARIO":"1","EMPRESA":"COVINOC S A","TIPO_DOCUMENTO":"N","ERROR":0,"SUCURSAL_USUARIO":"BOGOTA","TOKEN":"29e16b4b3926ded2f2eb65e76b0fc29a","NOMBRE":"CLAUDIA  GRAU ","MENSAJE":""}};
  let user = JSON.parse(localStorage.getItem("user"));
  return (dispatch) => {
    dispatch(success(user));
  };
  function success(user) {
    return { type: GET_USERDATA, user };
  }
};
