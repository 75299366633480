import config from "../../config";
import { authHeader } from "../../helpers";

export const getTransactions = ({
  customerDocumentType,
  customerDocument,
  transactionStatus,
  branch,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: "GET",
    headers,
  };
  let finalParams = `&fl_estado=${transactionStatus}${
    branch ? "&fl_sucursal=" + branch : ""
  }${
    customerDocumentType
      ? "&afiliado_tipo_documento=" + customerDocumentType
      : ""
  }${customerDocument ? "&afiliado_documento=" + customerDocument : ""}`;
  return fetch(
    `${config.apiUrl}/pr_transacciones?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getCostumerStates = ({
  customerDocumentType,
  customerDocument,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: "GET",
    headers,
  };
  let finalParams = `afiliado_tipo_documento=${customerDocumentType}&afiliado_documento=${customerDocument}`;
  return fetch(
    `${config.apiUrl}/pr_listado_departamentos_afiliado?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getCostumerCities = ({
  customerDocumentType,
  customerDocument,
  department,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: "GET",
    headers,
  };
  let finalParams = `afiliado_tipo_documento=${customerDocumentType}&afiliado_documento=${customerDocument}&departamento=${department}`;
  return fetch(
    `${config.apiUrl}/pr_listado_municipios_afiliado?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getCostumersLocation = ({
  customerDocumentType,
  customerDocument,
  lat,
  long,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: "GET",
    headers,
  };
  let finalParams = `afiliado_tipo_documento=${customerDocumentType}&afiliado_documento=${customerDocument}&cantidad=10&latitud=${lat}&longitud=${long}`;
  return fetch(
    `${config.apiUrl}/pr_geolocalizacion?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getPaymentMethods = ({ documentType, documentNumber }) => {
  const headers = authHeader();
  const requestOptions = {
    method: "GET",
    headers,
  };
  let finalParams = `tipo_documento=${documentType}&documento=${documentNumber}`;
  return fetch(
    `${config.apiUrl}/pr_metodos_pago?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getReference = ({
  obligation,
  documentType,
  documentNumber,
  online,
  amount,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: "GET",
    headers,
  };
  let finalParams = `obligacion=${obligation}&tipo_documento=${documentType}&documento=${documentNumber}&tipo_pago=PAGO TOTAL&pagoEnLinea=${online}&valor_pagado=${amount}`;
  return fetch(
    `${config.apiUrl}/retornar_referencia_pago?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getUrlBaloto = ({
  obligation,
  documentType,
  documentNumber,
  amount,
  taxAmount,
  expirationDate,
  productCode,
  customerName,
}) => {
  const requestOptions = {
    method: "GET",
  };
  let finalParams = `NO_ORDEN_INGRESO=${obligation}&TOTAL_CON_IVA=${amount}&DOC_DEUDOR=${documentNumber}&FECHA_VENCIMIENTO=${expirationDate}&TIPO_DOC_DEUDOR=${documentType}&TOTAL_IVA=${taxAmount}&COD_PRODUCTO=${productCode}&DEUDOR=${customerName}&url_retorno=${config.hostUrl}&email=prueba@gmail.com`;
  return fetch(
    `https://cobra.covinoc.com:18443/servicios_pagos/servicios/generar_pago?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getPayzenData = ({
  documentType,
  documentNumber,
  amount,
  reference,
}) => {
  const requestOptions = {
    method: "GET",
  };
  let finalParams = `id_pais=1&vads_shop_name=Covifactura para tesos&vads_shop_url=${config.hostUrl}&vads_url_return=${config.hostUrl}&vads_order_id=${reference}&documento=${documentNumber}&tipo_documento=${documentType}&vads_amount=${amount}&vads_cust_id=${documentNumber}&vads_cust_email=prueba@gmail.com`;
  return fetch(
    `http://200.41.76.20:8080/PayzenCorporativo/servicios/retornar_datos_pago_payzen_prod?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const exportPaymentPdf = ({
  documentType,
  documentNumber,
  amount,
  reference,
  obligation,
}) => {
  const requestOptions = {
    method: "GET",
  };
  let finalParams = `documento=${documentNumber}&tipo_documento=${documentType}&obligacion=${obligation}&valor_pagado=${amount}&referencia=${reference}`;
  return fetch(
    `http://200.41.76.20:8080/MiCovinocV2/servicios/exportar_orden_pago?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const exportPaymentEmail = ({
  documentType,
  documentNumber,
  amount,
  reference,
  obligation,
  email,
}) => {
  const requestOptions = {
    method: "GET",
  };
  let finalParams = `email=${email}&documento=${documentNumber}&tipo_documento=${documentType}&obligacion=${obligation}&valor_pagado=${amount}&referencia=${reference}`;
  return fetch(
    `${config.apiUrl}/orden_pago_email?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

function handleResponse(response) {
  if (
    response.headers.get("content-type").indexOf("application/ms-excel") !== -1
  ) {
    return response.blob().then((blob) => {
      return blob;
    });
  }
  if (response.headers.get("content-type").indexOf("application/pdf") !== -1) {
    return response.blob().then((blob) => {
      return blob;
    });
  }
  return response.text().then((text) => {
    if (
      response.headers.get("content-type").indexOf("application/pdf") !== -1
    ) {
      return text;
    }
    const data = text && response.ok && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.reload(true);
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
      };
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
