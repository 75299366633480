import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './header.scss';
import * as actions from '../../redux/actions';
import { Col, Navbar, Row } from 'react-bootstrap';
// import tesoslogo from '../../assets/logos/loginlogo.svg';
import tesoslogo from '../../assets/logos/newlogo.svg';

import menunewicon from '../../assets/icons/menunewicon.svg';
import newchat from '../../assets/icons/newchat.svg';
import chatnewicon from '../../assets/icons/chatnewicon.svg';
import purchases from '../../assets/icons/purchaseswhite.svg';
import shops from '../../assets/icons/shopswhite.svg';
import carnewicon from '../../assets/icons/carnewicon.svg';
import carnewiconselected from '../../assets/icons/carnewiconselected.svg';
import stablishmentnewicon from '../../assets/icons/stablishmentnewicon.svg';
import stablishmentnewiconselected from '../../assets/icons/stablishmentnewiconselected.svg';
import logouticon from '../../assets/icons/logouticon.svg';
import backarrow from '../../assets/icons/backarrowwhite.svg';
import bigbackarrow from '../../assets/icons/bigbackarrow.svg';
import config from '../../config';

class Header extends Component {
  openMenu() {
    this.props.openMenu();
  }

  goBack = () => {
    this.props.history.goBack();
  };

  goChat = () => {
    // window.$zopim.livechat.departments.filter("Mi Covinoc");
    // window.$zopim.livechat.departments.setVisitorDepartment('Mi Covinoc');
    window.$zopim.livechat.window.show();
  };

  goEstablishments = () => {
    this.props.history.push(config.publicRoute + '/establecimientos');
  };

  goToHome = () => {
    this.props.history.push(config.publicRoute + '/');
  };

  signoff = () => {
    this.props.actions.cleanUserContact();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('loginDate');
    this.props.history.push(config.publicRoute + '/login');
  };

  render() {
    const { menu, tab, second } = this.props;
    let centerGrid = menu ? { span: 8 } : { span: 8, offset: 2 };
    return (
      <Navbar sticky='top' className='header'>
        <Col xs={12}>
          <Row className='header-logo-container'>
            <Col xs={12} className='no-padding full-center hide-desktop'>
              <button
                className='search-button'
                onClick={(e) => {
                  e.preventDefault();
                  this.openMenu();
                }}
              >
                <img
                  className='menu-icon'
                  src={menunewicon}
                  alt='icono de menú'
                />
              </button>
              <button
                className={
                  tab === 1 && !second
                    ? 'search-button selected hide-desktop'
                    : 'search-button hide-desktop'
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.goToHome();
                }}
              >
                <img
                  className='header-tab-icon'
                  src={tab === 1 && !second ? carnewiconselected : carnewicon}
                  alt='icono de menú'
                />
              </button>
              <button
                className={
                  tab === 2 && !second
                    ? 'search-button selected hide-desktop'
                    : 'search-button hide-desktop'
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.goEstablishments();
                }}
              >
                <img
                  className='header-tab-icon'
                  src={
                    tab === 2 && !second
                      ? stablishmentnewiconselected
                      : stablishmentnewicon
                  }
                  alt='icono de menú'
                />
              </button>
              <button
                className='search-button hide-desktop'
                onClick={(e) => {
                  e.preventDefault();
                  this.goChat();
                }}
              >
                <img
                  className='search-icon'
                  src={chatnewicon}
                  alt='icono de chat'
                />
              </button>
            </Col>
            <Col
              xs={{ span: 2 }}
              className='no-padding full-center hide-mobile'
            >
              <button
                className={
                  tab === 1 && !second
                    ? 'search-button selected'
                    : 'search-button'
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.goToHome();
                }}
              >
                <img
                  className='header-tab-icon'
                  src={purchases}
                  alt='icono de menú'
                />
              </button>
              <button
                className={
                  tab === 2 && !second
                    ? 'search-button selected'
                    : 'search-button'
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.goEstablishments();
                }}
              >
                <img
                  className='header-tab-icon'
                  src={shops}
                  alt='icono de menú'
                />
              </button>
              {/* <button
                  className="search-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.goChat();
                  }}
                  >
                  <img className="header-tab-icon" src={newchat} alt='icono de menú'/>
                </button> */}
            </Col>
            {/* {second && (
              <Col xs={{ span: 2 }} className="no-padding full-center hide-mobile">
                <button
                  className="back-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.goBack();
                  }}
                  >
                  <img className="header-tab-icon" src={backarrow} alt='icono de menú'/>
                  Volver
                </button>
              </Col>
            )} */}
            {/*
              {second && (
              <Col xs={{ span: 2 }} className="no-padding full-center hide-desktop">
              <button
              className="back-button"
              onClick={(e) => {
              e.preventDefault();
              this.goBack();
              }}
              >
              <img className="header-tab-icon-back" src={bigbackarrow} alt='icono de menú'/>
              </button>
              </Col>
              )}
              */}
            <Col xs={centerGrid} className='center-horizontally hide-mobile'>
              <img
                className='header-logo'
                src={tesoslogo}
                alt='covifactura para tesos logo'
              />
            </Col>
            <Col
              xs={{ span: 2 }}
              className='no-padding full-center hide-mobile'
            >
              <button
                className='log-out-button'
                onClick={(e) => {
                  e.preventDefault();
                  this.signoff();
                }}
              >
                Cerrar sesión
                <img
                  className='search-icon'
                  src={logouticon}
                  alt='icono de cerrar sesion'
                />
              </button>
            </Col>
          </Row>
        </Col>
      </Navbar>
    );
  }
}

Header.propTypes = {
  menu: PropTypes.bool,
  openMenu: PropTypes.func,
  tab: PropTypes.number,
  second: PropTypes.bool,
};

Header.defaultProps = {
  menu: false,
  openMenu: () => {},
  tab: 1,
  second: false,
};

const mapStateToProps = (state, props) => {
  let { user } = state.user;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
