import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import './menu.scss';
import * as actions from '../../redux/actions';
import { Col, Row } from 'react-bootstrap';
import companyLogo from '../../assets/logos/onboardinglogo.svg';
import closeIcon from '../../assets/icons/exitblue.svg';
import arrownext from '../../assets/icons/arrownext.svg';
import covinocLogo from '../../assets/logos/logocovinoc.svg';
import clienticon from '../../assets/icons/clienticon.svg';
import firmecard from '../../assets/icons/firmecard.svg';
import config from '../../config';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.loadData();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  loadData() {
    this.props.actions.getUser();
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeMenu();
    }
  }

  closeMenu() {
    this.props.closeMenu();
  }

  signoff = () => {
    this.props.actions.cleanUserContact();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('loginDate');
    this.props.history.push(config.publicRoute + '/login');
  };

  render() {
    const { isOpen, user } = this.props;
    let userName = user
      ? `${user.userInfo.NOMBRES} ${user.userInfo.APELLIDOS}`
      : '';

    return (
      <div
        ref={this.setWrapperRef}
        className={
          isOpen ? 'menu menu-open col-md-12 col-12' : 'menu col-md-auto col-12'
        }
      >
        <Row>
          <Col xs={12} className='menu-header'>
            <div className='close-container'>
              <button
                className='close'
                onClick={(e) => {
                  e.preventDefault();
                  this.closeMenu();
                }}
              >
                <img src={closeIcon} alt='close icon' />
              </button>
            </div>
          </Col>
          <Col xs={12}>
            <Row>
              <button
                className='menu-item'
                onClick={(e) => {
                  e.preventDefault();
                  // this.signoff();
                }}
              >
                <Row>
                  <Col xs='auto' className='full-center'>
                    <img
                      className='client-icon'
                      src={clienticon}
                      alt='icono de cliente'
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Row>
                        <Col xs={12} className='clien-key'>
                          Nombre
                        </Col>
                        <Col xs={12} className='client-value'>
                          {userName.toLowerCase()}
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                </Row>
              </button>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>
              <button
                className='menu-item'
                onClick={(e) => {
                  e.preventDefault();
                  // this.signoff();
                }}
              >
                <Row>
                  <Col
                    xs='auto'
                    className='full-center creditcard-icon-container'
                  >
                    <img
                      className='creditcard-icon'
                      src={firmecard}
                      alt='icono de tarjeta'
                    />
                  </Col>
                  <Col xs='auto'>
                    <Row className='client-card-container-text'>
                      <div className='clien-key'>Cliente </div>
                      <div className='client-value'>Firme</div>
                    </Row>
                  </Col>
                </Row>
              </button>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>
              <button
                className='menu-item'
                onClick={(e) => {
                  e.preventDefault();
                  this.signoff();
                }}
              >
                <Row>
                  <Col className='menu-item_title'>Cerrar sesión</Col>
                  <Col xs='auto'>
                    <img
                      className='box-icon'
                      src={arrownext}
                      alt='icono de flecha siguiente'
                    />
                  </Col>
                </Row>
              </button>
            </Row>
          </Col>
          {/*
            <Col xs={12} >
            <div className="menu-header-logo" >
            <img className="company-logo" src={companyLogo} alt='company logo'/>
            </div>
            </Col>
            */}
        </Row>
        <div className='menu-footer'>
          <span className='menu-footer_text'>© 2020 | Un servicio de </span>
          <img
            className='menu-footer_logo'
            src={covinocLogo}
            alt='covinoc logo'
          />
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  isOpen: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
};

Menu.defaultProps = {
  isOpen: false,
  closeMenu: () => {},
};

const mapStateToProps = (state, props) => {
  let { user } = state.user;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
