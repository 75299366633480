import React, { Component } from "react";
import { PropTypes } from "prop-types";

import "./transaction-item.scss";

import { Row, Col } from "react-bootstrap";
import logoEstablishment from "../../assets/images/iconindustry.svg";
import downrow from "../../assets/icons/downrow.svg";
import rigthrow from "../../assets/icons/rigthrow.svg";

class TransactionItem extends Component {
  state = {
    isOpen: false,
  };

  changeOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  getColorClass = (status) => {
    let colorClass = "";
    switch (status) {
      case "AL DIA":
        colorClass = "green";
        break;
      case "VENCIDO":
        colorClass = "yellow";
        break;
      case "RECLAMADA":
        colorClass = "red";
        break;
      default:
        break;
    }
    return colorClass;
  };

  render() {
    const { isOpen } = this.state;
    const {
      item,
      dateText,
      dateBuyText,
      days,
      stablishment,
      effective,
      quota,
    } = this.props;
    return (
      <Col
        xs={12}
        className={`transaction-item${
          stablishment ? " item-stablishment" : ""
        }`}
        // onClick={(e) => {
        //   e.preventDefault();
        //   if (window.innerWidth < 1024) {
        //     this.goDetail(item);
        //   }
        // }}
      >
        {item.TITULO && (
          <Row className="item-container">
            <Col>
              <Row className="item-container">
                {!stablishment && (
                  <Col xs={12} md="auto" className="hide-mobile">
                    <div className="establishment-logo-img-container">
                      <img
                        className="establishment-logo"
                        src={
                          quota && "LOGO_EMPRESA" in quota
                            ? quota.LOGO_EMPRESA
                            : logoEstablishment
                        }
                        alt=""
                      />
                    </div>
                  </Col>
                )}
                <Col xs={12} md>
                  <Row>
                    <Col xs={12} className="item-name">
                      {item.ESTABLECIMIENTO}
                      <div className="hide-desktop">
                        <button
                          className="open-button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.changeOpen();
                          }}
                        >
                          <img
                            src={isOpen ? downrow : rigthrow}
                            alt="icono de flecha"
                          />
                        </button>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      className={`item-pay-value${
                        item.TITULO === "FACTURA" ? " invoice" : ""
                      }`}
                    >
                      <span>{item.TITULO.toLowerCase()}</span> por $
                      {this.getNumber(item.VALOR_GARANTIZADO)}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md>
                  <Row>
                    {!effective && (
                      <Col xs={12} className="item-value">
                        Fecha pago:{" "}
                        <span className={this.getColorClass(item.ESTADO)}>
                          {dateText}
                        </span>
                      </Col>
                    )}
                    {effective && (
                      <Col xs={12} className="item-value">
                        Fecha de compra: <span>{dateBuyText}</span>
                      </Col>
                    )}
                    {effective && (
                      <Col xs={12} className="item-value hide-mobile">
                        Número de {item.TITULO.toLowerCase()}:{" "}
                        <span>
                          {item.TITULO === "CHEQUE"
                            ? "cheque"
                            : item.TITULO_VALOR}
                        </span>
                      </Col>
                    )}
                    {!effective &&
                      days < 16 &&
                      item.ESTADO !== "RECLAMADA" &&
                      !(item.ESTADO === "AL DIA" && days < 0) && (
                        <Col xs={12} className="item-value">
                          Días para el pago:
                          {days > 0 && (
                            <span className={this.getColorClass(item.ESTADO)}>
                              {days} días
                            </span>
                          )}
                          {days < 0 && (
                            <span className={this.getColorClass(item.ESTADO)}>
                              {/* {days * -1} */}
                              Vencida
                            </span>
                          )}
                          {days === 0 && (
                            <span className={this.getColorClass(item.ESTADO)}>
                              Vence hoy
                            </span>
                          )}
                        </Col>
                      )}
                    {item.ESTADO === "RECLAMADA" && (
                      <Col xs={12} className="item-value">
                        Días para el pago:
                        <span className={this.getColorClass(item.ESTADO)}>
                          Vencida
                        </span>
                      </Col>
                    )}
                  </Row>
                </Col>
                {effective && (
                  <Col xs={12} md>
                    <Row>
                      <Col xs={12} className="item-value">
                        Pagado el: <span>{dateText}</span>
                      </Col>
                    </Row>
                  </Col>
                )}
                {/* {item.ESTADO === "RECLAMADA" && (
                  <Col xs={12} md className="hide-mobile">
                    <Row>
                      <Col xs={12}>
                        <button
                          className="button-pay"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.goDetail(item);
                          }}
                        >
                          PAGAR
                        </button>
                      </Col>
                    </Row>
                  </Col>
                )} */}
                {isOpen && (
                  <Col xs={12} md>
                    <Row>
                      {!effective && (
                        <Col xs={12} className="item-value">
                          Fecha de compra: <span>{dateBuyText}</span>
                        </Col>
                      )}
                      <Col xs={12} className="item-value">
                        Número de {item.TITULO.toLowerCase()}:{" "}
                        <span>
                          {item.TITULO === "CHEQUE"
                            ? "cheque"
                            : item.TITULO_VALOR}
                        </span>
                      </Col>
                      <Col xs={12} className="item-value">
                        Sucursal:<span>{item.SUCURSAL.toLowerCase()}</span>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        )}
        {/* {item.ESTADO === "RECLAMADA" && (
          <div className="button-container hide-desktop">
            <button
              onClick={(e) => {
                e.preventDefault();
                this.props.goDetail(item);
              }}
            >
              PAGAR
            </button>
          </div>
        )} */}
      </Col>
    );
  }
}

TransactionItem.propTypes = {
  item: PropTypes.object,
  quota: PropTypes.object,
  dateText: PropTypes.string,
  dateBuyText: PropTypes.string,
  days: PropTypes.string,
  effective: PropTypes.bool,
  stablishment: PropTypes.bool,
  goDetail: PropTypes.func,
};

TransactionItem.defaultProps = {
  item: {},
  quota: null,
  dateText: "",
  dateBuyText: "",
  days: "",
  effective: false,
  stablishment: false,
  goDetail: () => {},
};

export default TransactionItem;
