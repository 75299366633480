import config from "../../config";
import { authHeader } from "../../helpers";

export const getGlobals = () => {
  const requestOptions = {
    method: "GET",
  };
  return fetch(`${config.apiUrl}/inicializar?id_pais=1`, requestOptions).then(
    handleResponse
  );
};

export const getCities = ({ departmentId }) => {
  const requestOptions = {
    method: "GET",
  };
  let finalParams = `id_departamento=${departmentId}`;
  return fetch(
    `${config.apiUrl}/municipios?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getUserContact = async ({ documentType, documentNumber }) => {
  const ipAddress = await getUserIpAddress();

  const requestOptions = {
    method: "GET",
  };
  let finalParams = `id_pais=1&tipo_documento=${documentType}&documento=${documentNumber}&ip=${ipAddress}`;
  return fetch(
    `${config.apiUrl}/datos_ubicacion?${finalParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const getUserIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    if (!response.ok) {
      throw new Error('Error al obtener la IP');
    }
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error al obtener la IP:', error);
    return null; 
  }
};

export const tokenLogin = ({
  documentType,
  documentNumber,
  loginToken,
}) => {
  const requestOptions = {
    method: "POST",
  };
  let finalParams = `id_pais=1&tipo_documento=${documentType}&documento=${documentNumber}&token_seguridad=${loginToken}`;
  return fetch(
    `${config.apiUrl}/acceso_url?${finalParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const sendCode = ({ contact, token }) => {
  const headers = { token: token };
  const requestOptions = {
    method: "POST",
    headers,
  };
  let finalParams = `dato=${contact}`;
  return fetch(
    `${config.apiUrl}/pr_envio_codigo?${finalParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const validateCode = ({ code, token }) => {
  const headers = { token: token };
  const requestOptions = {
    method: "GET",
    headers,
  };
  let finalParams = `codigo=${code}`;
  return fetch(
    `${config.apiUrl}/pr_validar_codigo?${finalParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const getUserQuota = () => {
  const headers = authHeader();
  const requestOptions = {
    method: "GET",
    headers,
  };
  return fetch(`${config.apiUrl}/pr_cupos`, requestOptions).then(
    handleResponse
  );
};

function handleResponseLogin(response) {
  return response.text().then((text) => {
    const data = text && response.ok && JSON.parse(text);
    if (!response.ok) {
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
      };
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}

function handleResponse(response) {
  if (
    response.headers.get("content-type").indexOf("application/ms-excel") !== -1
  ) {
    return response.blob().then((blob) => {
      return blob;
    });
  }
  return response.text().then((text) => {
    const data = text && response.ok && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.reload(true);
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
      };
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
