import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import "./detail.scss";
import * as actions from "../../redux/actions";
import { Row, Col } from "react-bootstrap";
import Header from "../header";
import Footer from "../footer";
import Chat from "../chat";
import ClientHeader from "../client-header";
import backarrow from "../../assets/icons/bigbackarrow.svg";
import Loading from "../loading";
import pdficon from "../../assets/icons/pdficon.svg";
import baloto from "../../assets/icons/baloto.png";
import onlinepayment from "../../assets/icons/onlinepayment.svg";
import arrownext from "../../assets/icons/arrownext.svg";
import downrow from "../../assets/icons/downrow.svg";
import rigthrow from "../../assets/icons/rigthrow.svg";
import FileSaver from "file-saver";

class Detail extends Component {
  constructor() {
    super();
    this.state = {
      uniqueCustomer: false,
      multipleCustomer: true,
      selectedCustomer: true,
      isOpen: false,
      isEmail: false,
    };
  }

  changeOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.actions.getUser();
    const userDocument = JSON.parse(localStorage.getItem("userDocument"));
    const { documentType, documentNumber } = userDocument;
    this.props.actions.getPaymentMethods({ documentType, documentNumber });
  }

  onInputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  getDiferenceDays = (expirationDate) => {
    let momentDate = moment(expirationDate);
    let momentTodayDate = moment();
    let days = momentDate.diff(momentTodayDate, "days");
    return [momentDate.format("DD/MMM/YYYY"), days];
  };

  goBack = () => {
    this.props.history.goBack();
  };

  listAcordeonItem = (itemIcon, itemText, onClick, onClickSecond) => {
    const { isOpen } = this.state;
    return (
      <Col xs={12}>
        <Col xs={12} className="payment-item-container item-acordeon">
          <button
            onClick={(e) => {
              e.preventDefault();
              this.changeOpen();
            }}
          >
            <Row className="payment-item">
              <Col xs="auto">
                <img
                  className={`${itemText === "" ? " item-img" : ""}`}
                  src={itemIcon}
                  alt="icono de item"
                />
              </Col>
              <Col className="payment-item-title">{itemText}</Col>
              <Col xs="auto">
                <img
                  className="box-icon"
                  src={isOpen ? downrow : rigthrow}
                  alt="icono de flecha siguiente"
                />
              </Col>
            </Row>
          </button>
        </Col>
        {isOpen && (
          <Col xs={12} className="open-container">
            <Row>
              <Col
                xs={12}
                className="acordeon-action separator-item"
                onClick={onClick}
              >
                Descargar PDF
              </Col>
              <Col xs={12} className="detail-title detail-gray separator-item">
                O enviar por correo a:
              </Col>
              <Col xs={12} className="acordeon-action" onClick={onClickSecond}>
                silvia.lopez@covinoc.com
              </Col>
            </Row>
          </Col>
        )}
        <Col xs={12}>
          <div className="item-border" />
        </Col>
      </Col>
    );
  };

  listItem = (itemIcon, itemText, onClick) => {
    return (
      <Col xs={12} className="payment-item-container">
        <button type="submit" onClick={onClick}>
          <Row className="payment-item">
            <Col xs="auto">
              <img
                className={`${itemText === "" ? " item-img" : ""}`}
                src={itemIcon}
                alt="icono de item"
              />
            </Col>
            <Col xs className="payment-item-title">
              {itemText}
            </Col>
            <Col xs="auto">
              <img
                className="box-icon"
                src={arrownext}
                alt="icono de flecha siguiente"
              />
            </Col>
            <Col xs={12}>
              <div className="item-border" />
            </Col>
          </Row>
        </button>
      </Col>
    );
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.paymentUrl &&
      nextProps.paymentUrl !== this.props.paymentUrl
    ) {
      window.location.replace(`${nextProps.paymentUrl.url}`);
    }

    if (
      nextProps.paymentMethods &&
      nextProps.paymentMethods !== this.props.paymentMethods
    ) {
      if (nextProps.paymentMethods.PAYZEN) {
        const { currentTransaction } = this.props;
        const userDocument = JSON.parse(localStorage.getItem("userDocument"));
        const { documentType, documentNumber } = userDocument;
        this.props.actions.getPayzenReference({
          obligation: currentTransaction.OBLIGACION,
          documentType,
          documentNumber,
          amount: currentTransaction.POLITICAS.COBROS.PAGO_TOTAL,
        });
      }
    }

    if (
      nextProps.payzenReference &&
      nextProps.payzenReference !== this.props.payzenReference &&
      "REFERENCIA_PAGO" in nextProps.payzenReference
    ) {
      const { currentTransaction } = this.props;
      let reference = nextProps.payzenReference.REFERENCIA_PAGO;
      const userDocument = JSON.parse(localStorage.getItem("userDocument"));
      const { documentType, documentNumber } = userDocument;
      this.props.actions.getPayzenData({
        documentType,
        documentNumber,
        amount: currentTransaction.POLITICAS.COBROS.PAGO_TOTAL,
        reference,
      });
    }

    if (
      nextProps.exportReference &&
      nextProps.exportReference !== this.props.exportReference &&
      "REFERENCIA_PAGO" in nextProps.exportReference
    ) {
      const { currentTransaction } = this.props;
      const { isEmail } = this.state;
      let reference = nextProps.payzenReference.REFERENCIA_PAGO;
      const userDocument = JSON.parse(localStorage.getItem("userDocument"));
      const { documentType, documentNumber } = userDocument;
      if (isEmail) {
        this.props.actions.exportPaymentEmail({
          documentType,
          documentNumber,
          amount: currentTransaction.POLITICAS.COBROS.PAGO_TOTAL,
          reference,
          obligation: currentTransaction.OBLIGACION,
          email: "silvia.lopez@covinoc.com",
        });
      } else {
        this.props.actions.exportPaymentPdf({
          documentType,
          documentNumber,
          amount: currentTransaction.POLITICAS.COBROS.PAGO_TOTAL,
          reference,
          obligation: currentTransaction.OBLIGACION,
        });
      }
    }
    if (nextProps.exportPdf && nextProps.exportPdf !== this.props.exportPdf) {
      console.log("nextProps.exportPdf", nextProps.exportPdf);
      this.savePaymentPdf(nextProps.exportPdf);
    }
  }

  savePaymentPdf(pdfBlob) {
    const { currentTransaction } = this.props;
    FileSaver.saveAs(pdfBlob, `${currentTransaction.OBLIGACION}.pdf`);
  }

  getPaymentUrl = (referenceNumber) => {
    const { currentTransaction } = this.props;
    const userDocument = JSON.parse(localStorage.getItem("userDocument"));
    const { documentType, documentNumber } = userDocument;
    let today = new Date();
    this.props.actions.getUrlBaloto({
      obligation: currentTransaction.OBLIGACION,
      documentType,
      documentNumber,
      amount: currentTransaction.POLITICAS.COBROS.PAGO_TOTAL,
      taxAmount:
        parseFloat(currentTransaction.POLITICAS.COBROS.PAGO_TOTAL) * 0.19,
      expirationDate: today.toJSON().split("T")[0],
      productCode: referenceNumber,
      customerName: currentTransaction.ESTABLECIMIENTO,
    });
  };

  exportPaymentPdf = (isEmail) => {
    const { currentTransaction } = this.props;
    const userDocument = JSON.parse(localStorage.getItem("userDocument"));
    const { documentType, documentNumber } = userDocument;
    this.setState(
      {
        isEmail,
      },
      () => {
        this.props.actions.getExportReference({
          obligation: currentTransaction.OBLIGACION,
          documentType,
          documentNumber,
          amount: currentTransaction.POLITICAS.COBROS.PAGO_TOTAL,
        });
      }
    );
  };

  sendToEmail = () => {
    const { currentTransaction } = this.props;
    const userDocument = JSON.parse(localStorage.getItem("userDocument"));
    const { documentType, documentNumber } = userDocument;
    this.props.actions.getExportReference({
      obligation: currentTransaction.OBLIGACION,
      documentType,
      documentNumber,
      amount: currentTransaction.POLITICAS.COBROS.PAGO_TOTAL,
    });
  };

  render() {
    const {
      currentTransaction,
      paymentMethods,
      loading,
      payzenData,
    } = this.props;
    let dateArray = this.getDiferenceDays(currentTransaction.FECHA_VENCIMIENTO);
    let dateText = dateArray[0];
    dateText = dateText.split(".").join("");
    return (
      <div className="detail">
        <Header menu openMenu={this.openMenu} second />
        <div className="container">
          {loading && <Loading />}
          <ClientHeader />
          <Col xs={4} md={2}>
            <button
              className="back-button"
              onClick={(e) => {
                e.preventDefault();
                this.goBack();
              }}
            >
              <img
                className="header-tab-icon"
                src={backarrow}
                alt="icono de menú"
              />
              Volver
            </button>
          </Col>
          <Col xs={12} className="detail-header-container">
            <Col xs={12} className="card">
              <Row className="detail-header-title-container">
                <Col xs={12} className="detail-header-title">
                  Detalle
                </Col>
                {currentTransaction && (
                  <Col xs={12}>
                    <Row className="purchases-title-container">
                      <Col xs={12} className="detail-title">
                        Afiliado:{" "}
                        <span>
                          {currentTransaction.ESTABLECIMIENTO.toLowerCase()}
                        </span>
                      </Col>
                      <Col xs={12} className="detail-title detail-gray">
                        Número de{" "}
                        {currentTransaction.TITULO === "CHEQUE"
                          ? "cheque"
                          : "factura"}
                        :<span>{currentTransaction.TITULO_VALOR}</span>
                      </Col>
                      <Col xs={12} className="detail-title detail-gray">
                        Fecha vencimiento:: <span>{dateText}</span>
                      </Col>
                    </Row>
                    <Row className="amount-title-container">
                      <Col
                        xs={12}
                        className="detail-title detail-gray center-text"
                      >
                        <span>Valor</span>
                      </Col>
                      <Col xs={12} className="amount-value">
                        $
                        {this.getNumber(
                          currentTransaction.POLITICAS.COBROS.PAGO_TOTAL
                        )}
                      </Col>
                    </Row>
                    <Row className="amount-title-container">
                      <Col xs={12} className="payment-methods-title">
                        Selecciona tu medio de pago
                      </Col>
                      {paymentMethods.ORDEN_PAGO &&
                        this.listAcordeonItem(
                          pdficon,
                          "Pagar en un banco",
                          (e) => {
                            e.preventDefault();
                            this.exportPaymentPdf(false);
                          },
                          (e) => {
                            e.preventDefault();
                            this.exportPaymentPdf(true);
                          }
                        )}
                      {payzenData && (
                        <form
                          method="POST"
                          className="payment-methods-form"
                          action="https://secure.payzen.lat/vads-payment/"
                        >
                          {Object.keys(payzenData).map((payzenDataKey) => {
                            return (
                              <input
                                key={payzenDataKey}
                                type="hidden"
                                name={payzenDataKey}
                                value={payzenData[payzenDataKey]}
                              />
                            );
                          })}
                          {this.listItem(onlinepayment, "Pagar en línea")}
                        </form>
                      )}
                      {paymentMethods.ZONA_PAGOS &&
                        this.listItem(baloto, "", (e) => {
                          e.preventDefault();
                          this.getPaymentUrl(paymentMethods.ZONA_PAGOS);
                        })}
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          </Col>
        </div>
        <Chat />
        <Footer tab={100} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { user, userQuota } = state.user;
  let {
    currentTransaction,
    currentCustomer,
    paymentMethods,
    loading,
    payzenReference,
    exportReference,
    paymentUrl,
    payzenData,
    exportPdf,
  } = state.transaction;
  return {
    user,
    userQuota,
    currentTransaction,
    currentCustomer,
    paymentMethods,
    loading,
    payzenReference,
    exportReference,
    paymentUrl,
    payzenData,
    exportPdf,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
