import { combineReducers } from 'redux'

import user from './user.reducer'
import transaction from './transaction.reducer'

const rootReducer = combineReducers({
  user,
  transaction
})

export default rootReducer
