import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import './global-search.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import arrownext from '../../assets/icons/arrownext.svg';
import Loading from '../loading';
import config from '../../config';

class GlobalSearch extends Component {
  constructor() {
    super();
    this.state = {
      uniqueCustomer: false,
      multipleCustomer: true,
      selectedCustomer: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userQuota !== this.props.userQuota) {
      if (nextProps.userQuota.CUPOS.length > 0) {
        this.setState(
          {
            selectedCustomer: nextProps.userQuota.CUPOS[0].AFILIADO_DOCUMENTO,
          },
          () => {
            let selectedQuota = nextProps.userQuota.CUPOS[0];
            this.props.actions.getTransactionsSearch({
              customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
              customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
              transactionStatus: 'AL DIA,NEGOCIADA',
              branch: null,
            });
            this.props.actions.setTransaction({
              currentTransaction: null,
              currentCustomer: nextProps.userQuota.CUPOS[0].AFILIADO_DOCUMENTO,
            });
          }
        );
      }
    }
  }

  goDetail = (item) => {
    const { selectedCustomer } = this.state;
    this.props.actions.setTransaction({
      currentTransaction: item,
      currentCustomer: selectedCustomer,
    });
    this.props.history.push(config.publicRoute + '/detalle');
  };

  loadData() {
    if (!this.props.userQuota) {
      this.getUserQuota();
    } else {
      if (!this.props.transactionsSearchResponse) {
        let selectedQuota = this.props.userQuota.CUPOS.find(
          (quota) => quota.AFILIADO_DOCUMENTO === this.props.currentCustomer
        );
        this.props.actions.getTransactionsSearch({
          customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
          customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
          transactionStatus: 'AL DIA,NEGOCIADA',
          branch: null,
        });
      }
    }
    if (this.props.currentCustomer) {
      this.setState({
        selectedCustomer: this.props.currentCustomer,
      });
    }
  }

  getUserQuota = () => {
    this.props.actions.getUserQuota();
  };

  onInputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  closeAction = () => {
    this.props.history.push(config.publicRoute + '/');
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    return thousand;
  }

  searchTermAction = (term) => {
    let selectedQuota = this.props.userQuota.CUPOS.find(
      (quota) => quota.AFILIADO_DOCUMENTO === this.props.currentCustomer
    );
    this.props.actions.getTransactionsSearch({
      customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
      customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
      transactionStatus: 'AL DIA,NEGOCIADA',
      branch: term,
    });
  };

  render() {
    const { selectedCustomer } = this.state;
    const {
      transactionLoading,
      transactionsRecordResponse,
      transactionsSearchResponse,
      userQuota,
      loading,
    } = this.props;
    let quotas = userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
    let defaultQuota = quotas.length > 0 ? quotas[0] : '';
    let selectedQuota =
      selectedCustomer !== ''
        ? quotas.find((quota) => quota.AFILIADO_DOCUMENTO === selectedCustomer)
        : defaultQuota;
    let transactions =
      transactionsRecordResponse &&
      'TRANSACCIONES' in transactionsRecordResponse
        ? transactionsRecordResponse.TRANSACCIONES
        : [];
    let transactionsSearch =
      transactionsSearchResponse &&
      'TRANSACCIONES' in transactionsSearchResponse
        ? transactionsSearchResponse.TRANSACCIONES
        : transactions;
    return (
      <div className='global-search'>
        <Header
          closeAction={this.closeAction}
          search={false}
          searching={true}
          searchTermAction={this.searchTermAction}
          searchPlaceholder='Buscar compras por establecimiento'
          tab={100}
        />
        <div className='container'>
          {(transactionLoading || loading) && <Loading />}
          <Col xs={12} className='purchases-container'>
            <Col xs={12} className='card'>
              {selectedQuota && (
                <Row className='purchases-items-container'>
                  {transactionsSearch.map((item, index) => {
                    return (
                      <Col key={index} xs={12}>
                        <button
                          className='next-icon-button'
                          onClick={(e) => {
                            e.preventDefault();
                            this.goDetail(item);
                          }}
                        >
                          <Row className='purchases-item'>
                            <Col>
                              <Row>
                                <Col xs={12} className='purchases-item-title'>
                                  {selectedQuota.AFILIADO_NOMBRES.toLowerCase()}{' '}
                                  - {item.SUCURSAL.toLowerCase()}
                                </Col>
                                <Col xs={12} className='purchases-item-value'>
                                  {item.TITULO === 'FACTURA' && (
                                    <span className='invoice'>Factura </span>
                                  )}
                                  {item.TITULO !== 'FACTURA' && (
                                    <span className='check'>Cheque</span>
                                  )}
                                  <span>
                                    {' '}
                                    - ${this.getNumber(item.VALOR_GARANTIZADO)}
                                  </span>
                                </Col>
                                <Col xs={12} className='purchases-item-date'>
                                  Fecha de pago:{' '}
                                  {item.FECHA_VENCIMIENTO === ''
                                    ? ''
                                    : moment(item.FECHA_VENCIMIENTO).format(
                                        'DD/MM/YYYY'
                                      )}
                                </Col>
                              </Row>
                            </Col>
                            <Col xs='auto'>
                              <img
                                className='box-icon'
                                src={arrownext}
                                alt='icono de flecha siguiente'
                              />
                            </Col>
                          </Row>
                        </button>
                        <Col xs={12} className='purchases-item-border'></Col>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Col>
          </Col>
        </div>
        <Chat />
        <Footer tab={100} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { loading, userQuota } = state.user;
  let {
    loading: transactionLoading,
    currentCustomer,
    transactionsRecordResponse,
    transactionsSearchResponse,
  } = state.transaction;
  return {
    currentCustomer,
    transactionLoading,
    transactionsRecordResponse,
    transactionsSearchResponse,
    loading,
    userQuota,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
