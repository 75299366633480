import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './location.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import Chat from '../chat';
import clienticon from '../../assets/icons/clienticon.svg';
// import googlemap from '../../assets/images/googlemap.png';
import backarrow from '../../assets/icons/backarrow.svg';
import Loading from '../loading';
import Menu from '../menu';
import config from '../../config';

// const customers = [
//   { id: 1, name: 'Madecentro'},
//   { id: 2, name: 'Alfagres'},
//   { id: 3, name: 'Casa Toro'},
// ];

// const departments = [
//   { id: 1, name: 'Cundinamarca'},
//   { id: 2, name: 'Antioquia'},
//   { id: 3, name: 'Valle del cauca'},
// ];
//
// const cities = [
//   { id: 1, name: 'Bogotá'},
//   { id: 2, name: 'Medellín'},
//   { id: 3, name: 'Cali'},
// ];

declare var google;

class Location extends Component {
  constructor() {
    super();
    this.map = null;
    this.markers = [];
    this.state = {
      uniqueCustomer: false,
      multipleCustomer: true,
      selectedCustomer: '',
      selectedCity: '',
      selectedDepartment: '',
      menuOpen: false,
    };
  }

  componentDidMount() {
    this.loadData();
    this.initMapPoint();
  }

  loadData() {
    this.getUserQuota();
  }

  getUserQuota = () => {
    this.props.actions.getUser();
    this.props.actions.getUserQuota();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userQuota !== this.props.userQuota) {
      if (nextProps.userQuota.CUPOS.length > 0) {
        this.setState(
          {
            selectedCustomer: nextProps.userQuota.CUPOS[0].AFILIADO_DOCUMENTO,
          },
          () => {
            let selectedQuota = nextProps.userQuota.CUPOS[0];
            this.props.actions.getCostumerStates({
              customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
              customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
            });
            this.props.actions.setTransaction({
              currentTransaction: null,
              currentCustomer: nextProps.userQuota.CUPOS[0].AFILIADO_DOCUMENTO,
            });
          }
        );
      }
    }
    if (nextProps.costumerStates !== this.props.costumerStates) {
      let states =
        nextProps.costumerStates && 'DEPARTAMENTOS' in nextProps.costumerStates
          ? nextProps.costumerStates.DEPARTAMENTOS
          : [];
      if (states.length > 0) {
        this.setState(
          {
            selectedDepartment: states[0].DEPARTAMENTO,
          },
          () => {
            const { selectedCustomer, selectedDepartment } = this.state;
            const { userQuota } = this.props;
            let quotas =
              userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
            let defaultQuota = quotas.length > 0 ? quotas[0] : '';
            let selectedQuota =
              selectedCustomer !== ''
                ? quotas.find(
                    (quota) => quota.AFILIADO_DOCUMENTO === selectedCustomer
                  )
                : defaultQuota;
            this.props.actions.getCostumerCities({
              customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
              customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
              department: selectedDepartment,
            });
          }
        );
      }
    }
    if (nextProps.costumerCities !== this.props.costumerCities) {
      let cities =
        nextProps.costumerCities && 'DEPARTAMENTOS' in nextProps.costumerCities
          ? nextProps.costumerCities.DEPARTAMENTOS
          : [];
      if (cities.length > 0) {
        this.setState(
          {
            selectedCity: cities[0].MUNICIPIO,
          },
          () => {
            const { selectedCustomer, selectedDepartment, selectedCity } =
              this.state;
            const { userQuota } = this.props;
            let quotas =
              userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
            let defaultQuota = quotas.length > 0 ? quotas[0] : '';
            let selectedQuota =
              selectedCustomer !== ''
                ? quotas.find(
                    (quota) => quota.AFILIADO_DOCUMENTO === selectedCustomer
                  )
                : defaultQuota;
            this.props.actions.getCostumersLocation({
              customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
              customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
              department: selectedDepartment,
              city: selectedCity,
            });
          }
        );
      }
    }
    if (nextProps.costumersLocation !== this.props.costumersLocation) {
      this.setMarkers(nextProps.costumersLocation);
    }
  }

  onInputChange = (event, input) => {
    const { selectedCustomer, selectedDepartment } = this.state;
    const { userQuota } = this.props;
    let quotas = userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
    let defaultQuota = quotas.length > 0 ? quotas[0] : '';
    let selectedQuota =
      selectedCustomer !== ''
        ? quotas.find((quota) => quota.AFILIADO_DOCUMENTO === selectedCustomer)
        : defaultQuota;
    if (input === 'selectedDepartment') {
      if (event.target.value === this.state.selectedDepartment) {
        return;
      }
      this.props.actions.getCostumerCities({
        customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
        customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
        department: event.target.value,
      });
    }
    if (input === 'selectedCity') {
      if (event.target.value === this.state.selectedCity) {
        return;
      }
      this.props.actions.getCostumersLocation({
        customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
        customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
        department: selectedDepartment,
        city: event.target.value,
      });
    }
    if (input === 'selectedCustomer') {
      if (event.target.value === this.state.selectedCustomer) {
        return;
      }
      let quotas = userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
      let defaultQuota = quotas.length > 0 ? quotas[0] : '';
      let selectedQuota =
        selectedCustomer !== ''
          ? quotas.find(
              (quota) => quota.AFILIADO_DOCUMENTO === event.target.value
            )
          : defaultQuota;
      this.props.actions.getCostumerStates({
        customerDocumentType: selectedQuota.AFILIADO_TIPO_DOCUMENTO,
        customerDocument: selectedQuota.AFILIADO_DOCUMENTO,
      });
      this.setState({
        [input]: event.target.value,
        selectedDepartment: '',
        selectedCity: '',
      });
      return;
    }
    this.setState({
      [input]: event.target.value,
    });
  };

  closeAction = () => {
    this.props.history.push(config.publicRoute + '/comercios');
  };

  initMapPoint() {
    console.log('entra a initMap');
    let lat = 4.60971;
    let long = -74.08175;
    let latLng = new google.maps.LatLng(lat, long);
    let mapOptions = {
      center: latLng,
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      disableDefaultUI: true,
      zoomControl: true,
      scaleControl: true,
      rotateControl: true,
    };
    this.map = new google.maps.Map(this.refs.map, mapOptions);
  }

  setMarkers = (costumersLocation) => {
    const { selectedCustomer } = this.state;
    const { userQuota } = this.props;
    let quotas = userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
    let defaultQuota = quotas.length > 0 ? quotas[0] : '';
    let selectedQuota =
      selectedCustomer !== ''
        ? quotas.find((quota) => quota.AFILIADO_DOCUMENTO === selectedCustomer)
        : defaultQuota;
    let quotaName =
      'AFILIADO_NOMBRES' in selectedQuota ? selectedQuota.AFILIADO_NOMBRES : '';

    this.clearMarkers();
    if ('DIRECCIONES' in costumersLocation) {
      let locations = costumersLocation.DIRECCIONES;
      for (let index in locations) {
        let lat = locations[index].LATITUD;
        let long = locations[index].LONGITUD;
        let latLng = new google.maps.LatLng(lat, long);
        let marker = new google.maps.Marker({
          position: latLng,
          map: this.map,
          title: quotaName + ' ' + locations[index].DIRECCION,
        });
        marker.setMap(this.map);
        this.map.panTo(marker.getPosition());

        let infowindow = new google.maps.InfoWindow({
          content: quotaName + ' ' + locations[index].DIRECCION,
        });

        marker.addListener('click', () => {
          infowindow.open(this.map, marker);
        });
        this.markers.push(marker);
      }
    }
  };

  clearMarkers = () => {
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
  };

  openMenu = () => {
    this.setState({
      menuOpen: true,
    });
  };

  closeMenu = () => {
    this.setState({
      menuOpen: false,
    });
  };

  render() {
    const {
      selectedCustomer,
      selectedCity,
      selectedDepartment,
      uniqueCustomer,
      multipleCustomer,
      menuOpen,
    } = this.state;
    const {
      userQuota,
      loading,
      user,
      transactionLoading,
      costumerStates,
      costumerCities,
      costumersLocation,
    } = this.props;
    let userName = user
      ? `${user.userInfo.NOMBRES} ${user.userInfo.APELLIDOS}`
      : '';
    let quotas = userQuota && 'CUPOS' in userQuota ? userQuota.CUPOS : [];
    let states =
      costumerStates && 'DEPARTAMENTOS' in costumerStates
        ? costumerStates.DEPARTAMENTOS
        : [];
    let cities =
      costumerCities && 'DEPARTAMENTOS' in costumerCities
        ? costumerCities.DEPARTAMENTOS
        : [];
    let locations =
      costumersLocation && 'DIRECCIONES' in costumersLocation
        ? costumersLocation.DIRECCIONES
        : [];
    console.log('costumersLocation', costumersLocation);
    return (
      <div className='location'>
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} />
        <Header search={false} menu openMenu={this.openMenu} tab={3} />
        <div className='container'>
          {(loading || transactionLoading) && <Loading />}
          <Col xs={12} className='client-container'>
            <Row>
              <Col xs='auto' className='full-center'>
                <img
                  className='client-icon'
                  src={clienticon}
                  alt='icono de cliente'
                />
              </Col>
              <Col>
                <Row>
                  <Row>
                    <Col xs={12} className='clien-key'>
                      Hola
                    </Col>
                    <Col xs={12} className='client-value'>
                      {userName.toLowerCase()}
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
          {(multipleCustomer || uniqueCustomer) && (
            <Col xs={12} className='client-container'>
              <Col xs={12}>
                <Row>
                  <Col xs={6} className='client-commerce'>
                    <Row>
                      <Col xs={12} className='clien-key'>
                        Cliente
                      </Col>
                      <Col xs={12} className='client-value'>
                        Premium
                      </Col>
                    </Row>
                  </Col>
                  {multipleCustomer && (
                    <Col xs={6} className='client-commerce'>
                      <Row>
                        <Col xs={12} className='clien-key'>
                          Seleccione afiliado
                        </Col>
                        <Col xs={12}>
                          <select
                            name='selectedCustomer'
                            className='client-value-select'
                            id='selectedCustomer'
                            onChange={(event) => {
                              this.onInputChange(event, 'selectedCustomer');
                            }}
                            onMouseDown={(event) => {
                              this.onInputChange(event, 'selectedCustomer');
                            }}
                            value={selectedCustomer}
                          >
                            {quotas.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item.AFILIADO_DOCUMENTO}
                                >
                                  {item.AFILIADO_NOMBRES}
                                </option>
                              );
                            })}
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {uniqueCustomer && (
                    <Col xs={6} className='client-commerce'>
                      <Row>
                        <Col xs={12} className='clien-key'>
                          Afiliado
                        </Col>
                        <Col xs={12} className='client-value'>
                          Casa Toro
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Col>
          )}
          <Col xs={12} className='purchases-container'>
            <Col xs={12} className='card'>
              {!multipleCustomer && !uniqueCustomer && (
                <Row className='purchases-title-container'>
                  <Col xs='auto' className='full-center'>
                    <button
                      className='back-button'
                      onClick={(e) => {
                        e.preventDefault();
                        this.closeAction();
                      }}
                    >
                      <img src={backarrow} alt='icono de atras' />
                    </button>
                  </Col>
                  <Col className='location_back-title'>Ferretería Gomez</Col>
                </Row>
              )}
              <Row className='purchases-title-container'>
                {(multipleCustomer || uniqueCustomer) && (
                  <Col xs={12}>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6} className='client-commerce'>
                          <Row>
                            <Col xs={12} className='clien-key'>
                              Departamento
                            </Col>
                            <Col xs={12}>
                              <select
                                name='selectedDepartment'
                                className='client-value-select'
                                id='selectedDepartment'
                                onChange={(event) => {
                                  this.onInputChange(
                                    event,
                                    'selectedDepartment'
                                  );
                                }}
                                onMouseDown={(event) => {
                                  this.onInputChange(
                                    event,
                                    'selectedDepartment'
                                  );
                                }}
                                value={selectedDepartment}
                              >
                                {states.map((item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={item.DEPARTAMENTO}
                                    >
                                      {item.DEPARTAMENTO}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={6} className='client-commerce'>
                          <Row>
                            <Col xs={12} className='clien-key'>
                              Ciudad
                            </Col>
                            <Col xs={12}>
                              <select
                                name='selectedCity'
                                className='client-value-select'
                                id='selectedCity'
                                onChange={(event) => {
                                  this.onInputChange(event, 'selectedCity');
                                }}
                                onMouseDown={(event) => {
                                  this.onInputChange(event, 'selectedCity');
                                }}
                                value={selectedCity}
                              >
                                {cities.map((item, index) => {
                                  return (
                                    <option key={index} value={item.MUNICIPIO}>
                                      {item.MUNICIPIO}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                )}
                <Col xs={12} className='map-container'>
                  {/*
                    <img className="google-map" src={googlemap} alt='icono de cliente'/>
                    */}
                  <div className='google-map' ref='map' />
                </Col>
                <Col xs={12} className='items-container'>
                  <Row>
                    {locations.map((item, index) => {
                      return (
                        <Col key={index} xs={12} className='location_item'>
                          <Row>
                            <Col xs={12} className='item-title'>
                              {item.CIUDAD.toLowerCase()}
                            </Col>
                            <Col xs={12} className='item-description'>
                              {item.DIRECCION}
                            </Col>
                            {/*
                              <Col xs={12} className="item-description">Horario: Lun - Sab de 8am - 7pm</Col>
                              */}
                            {/*
                              <Col xs={12} className="item-description">Contacto: (1)633 9987 - 300 890 7766</Col>
                              */}
                          </Row>
                          <Col xs={12} className='location-item_border' />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Col>
        </div>
        <Chat />
        <Footer tab={3} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { globals, loading, userQuota, user } = state.user;
  let {
    loading: transactionLoading,
    currentCustomer,
    costumerStates,
    costumerCities,
    costumersLocation,
  } = state.transaction;
  return {
    globals,
    loading,
    userQuota,
    user,
    transactionLoading,
    currentCustomer,
    costumerStates,
    costumerCities,
    costumersLocation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
