import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom';

import './client-header.scss';
import * as actions from '../../redux/actions'
import { Col, Row } from 'react-bootstrap';
import firmecard from '../../assets/icons/firmecard.svg';
import clienticon from '../../assets/icons/clienticon.svg';

class ClientHeader extends Component {

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.props.actions.getUser();
  }

  render() {
    const { user } = this.props;
    let userName = user ? `${user.userInfo.NOMBRES} ${user.userInfo.APELLIDOS}` : ''

    return (
      <Col xs={12} className="client-header hide-mobile">
        <Col xs={12}>
          <Row>
            <Col xs={8}>
              <Row>
                <Col xs="auto" className="full-center">
                  <img className="client-icon" src={clienticon} alt='icono de cliente'/>
                </Col>
                <Col>
                  <Row>
                    <Row>
                      <Col xs={12} className="clien-key">Nombre</Col>
                      <Col xs={12} className="client-value">{userName.toLowerCase()}</Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row className="client-card-container">
                <Col xs="auto" className="full-center creditcard-icon-container">
                  <img className="creditcard-icon" src={firmecard} alt='icono de tarjeta'/>
                </Col>
                <Col xs="auto">
                  <Row className="client-card-container-text">
                    <div className="clien-key">Cliente </div>
                    <div className="client-value">Firme</div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Col>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
      user
  } = state.user;
  return {
    user
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientHeader));
