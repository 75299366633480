import {
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_RECORD_REQUEST,
  GET_TRANSACTIONS_RECORD_SUCCESS,
  GET_TRANSACTIONS_RECORD_FAILURE,
  GET_TRANSACTIONS_PURCHASES_REQUEST,
  GET_TRANSACTIONS_PURCHASES_SUCCESS,
  GET_TRANSACTIONS_PURCHASES_FAILURE,
  GET_TRANSACTIONS_SEARCH_REQUEST,
  GET_TRANSACTIONS_SEARCH_SUCCESS,
  GET_TRANSACTIONS_SEARCH_FAILURE,
  GET_COSTUMER_STATES_REQUEST,
  GET_COSTUMER_STATES_SUCCESS,
  GET_COSTUMER_STATES_FAILURE,
  GET_COSTUMER_CITIES_REQUEST,
  GET_COSTUMER_CITIES_SUCCESS,
  GET_COSTUMER_CITIES_FAILURE,
  GET_COSTUMERS_LOCATION_REQUEST,
  GET_COSTUMERS_LOCATION_SUCCESS,
  GET_COSTUMERS_LOCATION_FAILURE,
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_REFERENCE_REQUEST,
  GET_PAYMENT_REFERENCE_SUCCESS,
  GET_PAYMENT_REFERENCE_FAILURE,
  GET_PAYMENT_EXPORT_REFERENCE_REQUEST,
  GET_PAYMENT_EXPORT_REFERENCE_SUCCESS,
  GET_PAYMENT_EXPORT_REFERENCE_FAILURE,
  GET_PAYMENT_URL_REQUEST,
  GET_PAYMENT_URL_SUCCESS,
  GET_PAYMENT_URL_FAILURE,
  GET_PAYZEN_DATA_REQUEST,
  GET_PAYZEN_DATA_SUCCESS,
  GET_PAYZEN_DATA_FAILURE,
  EXPORT_PDF_REQUEST,
  EXPORT_PDF_SUCCESS,
  EXPORT_PDF_FAILURE,
  EXPORT_EMAIL_REQUEST,
  EXPORT_EMAIL_SUCCESS,
  EXPORT_EMAIL_FAILURE,
  SET_TRANSACTION,
} from "../action-types";

const initialState = {
  loading: false,
  transactionsResponse: false,
  loadingRecord: false,
  transactionsRecordResponse: false,
  loadingPurchases: false,
  transactionsPurchasesResponse: false,
  transactionsSearchResponse: false,
  currentTransaction: false,
  costumerStates: false,
  costumerCities: false,
  costumersLocation: false,
  paymentMethods: false,
  payzenReference: false,
  exportReference: false,
  paymentUrl: false,
  exportPdf: false,
  exportEmail: false,
  payzenData: false,
  currentCustomer: false,
  error: false,
};

const transactions = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_TRANSACTIONS_SUCCESS: {
      let transactionsResponse = action.data;
      return {
        ...state,
        transactionsResponse,
        error: false,
        loading: false,
      };
    }

    case GET_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_TRANSACTIONS_RECORD_REQUEST: {
      return {
        ...state,
        loadingRecord: true,
      };
    }

    case GET_TRANSACTIONS_RECORD_SUCCESS: {
      let transactionsRecordResponse = action.data;
      return {
        ...state,
        transactionsRecordResponse,
        error: false,
        loadingRecord: false,
      };
    }

    case GET_TRANSACTIONS_RECORD_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingRecord: false,
      };
    }

    case GET_TRANSACTIONS_PURCHASES_REQUEST: {
      return {
        ...state,
        loadingPurchases: true,
      };
    }

    case GET_TRANSACTIONS_PURCHASES_SUCCESS: {
      let transactionsPurchasesResponse = action.data;
      return {
        ...state,
        transactionsPurchasesResponse,
        error: false,
        loadingPurchases: false,
      };
    }

    case GET_TRANSACTIONS_PURCHASES_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingPurchases: false,
      };
    }

    case GET_TRANSACTIONS_SEARCH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_TRANSACTIONS_SEARCH_SUCCESS: {
      let transactionsSearchResponse = action.data;
      return {
        ...state,
        transactionsSearchResponse,
        error: false,
        loading: false,
      };
    }

    case GET_TRANSACTIONS_SEARCH_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_COSTUMER_STATES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_COSTUMER_STATES_SUCCESS: {
      let costumerStates = action.data;
      return {
        ...state,
        costumerStates,
        error: false,
        loading: false,
      };
    }

    case GET_COSTUMER_STATES_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_COSTUMER_CITIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_COSTUMER_CITIES_SUCCESS: {
      let costumerCities = action.data;
      return {
        ...state,
        costumerCities,
        error: false,
        loading: false,
      };
    }

    case GET_COSTUMER_CITIES_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_COSTUMERS_LOCATION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_COSTUMERS_LOCATION_SUCCESS: {
      let costumersLocation = action.data;
      return {
        ...state,
        costumersLocation,
        error: false,
        loading: false,
      };
    }

    case GET_COSTUMERS_LOCATION_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_PAYMENT_METHODS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PAYMENT_METHODS_SUCCESS: {
      let paymentMethods = action.data;
      return {
        ...state,
        paymentMethods,
        error: false,
        loading: false,
      };
    }

    case GET_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_PAYMENT_REFERENCE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PAYMENT_REFERENCE_SUCCESS: {
      let payzenReference = action.data;
      return {
        ...state,
        payzenReference,
        error: false,
        loading: false,
      };
    }

    case GET_PAYMENT_REFERENCE_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_PAYMENT_EXPORT_REFERENCE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PAYMENT_EXPORT_REFERENCE_SUCCESS: {
      let exportReference = action.data;
      return {
        ...state,
        exportReference,
        error: false,
        loading: false,
      };
    }

    case GET_PAYMENT_EXPORT_REFERENCE_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_PAYMENT_URL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PAYMENT_URL_SUCCESS: {
      let paymentUrl = action.data;
      return {
        ...state,
        paymentUrl,
        error: false,
        loading: false,
      };
    }

    case GET_PAYMENT_URL_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_PAYZEN_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PAYZEN_DATA_SUCCESS: {
      let payzenData = action.data;
      return {
        ...state,
        payzenData,
        error: false,
        loading: false,
      };
    }

    case GET_PAYZEN_DATA_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case EXPORT_PDF_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case EXPORT_PDF_SUCCESS: {
      let exportPdf = action.data;
      return {
        ...state,
        exportPdf,
        error: false,
        loading: false,
      };
    }

    case EXPORT_PDF_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case EXPORT_EMAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case EXPORT_EMAIL_SUCCESS: {
      let exportEmail = action.data;
      return {
        ...state,
        exportEmail,
        error: false,
        loading: false,
      };
    }

    case EXPORT_EMAIL_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case SET_TRANSACTION: {
      let currentTransaction = action.data.currentTransaction;
      let currentCustomer = action.data.currentCustomer;
      return {
        ...state,
        currentTransaction,
        currentCustomer,
        error: false,
      };
    }

    default:
      return state;
  }
};

export default transactions;
